import React from 'react';
import { Anchor, Box, Text } from 'grommet';
import { SecondaryHeading } from '../Shared';
import PrimaryHeading from '../Shared/PrimaryHeading';

const OutroText = () => (
  <Box gap="large">
    <PrimaryHeading>
      What&apos;s next?
    </PrimaryHeading>

    <Box gap="medium">
      <SecondaryHeading>
        Like your results?
      </SecondaryHeading>
      <Text>
        Take a screenshot and share it with us by tagging @codesignstudio, we would love to see the
        projects with which you matched.
      </Text>
    </Box>

    <Box gap="medium">
      <SecondaryHeading>
        Not sure if these are the projects for you?
      </SecondaryHeading>
      <Text>
        We are always keen to refine the tool and your feedback does make a difference,
        email us at&nbsp;
        <Anchor
          href="mailto:info@codesignstudio.com.au?subject=Feedback"
          primary
          label={<Text weight="normal">info@codesignstudio.com.au</Text>}
        />
        &nbsp;with &apos;Feedback&apos; in the subject line.
      </Text>
    </Box>

    <Box gap="medium">
      <SecondaryHeading>
        How can I learn more?
      </SecondaryHeading>
      <Text>
        We run online trainings including regular free webinars on locally-led placemaking and how
        you can build your capacity to deliver projects in your area. Sign up to our newsletter for
        updates at&nbsp;
        <Anchor
          href="//www.codesignstudio.com.au"
          target="_blank"
          primary
          label={<Text weight="normal">www.codesignstudio.com.au</Text>}
        />
      </Text>
    </Box>
  </Box>
);

export default OutroText;
