import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import Image from '../Image';
import { imgType } from '../../helpers/propTypes';
import ImageContainer from './ImagesContainer';
import SwiperContainer from './SwiperContainer';
import ProgressBar from './ProgressBar';


/**
 * Component that renders a given array of images,
 * although only the first image is actually displayed.
 * The remaining images are rendered invisibly, in order
 * to be preloaded for later use.
 * */
const Swiper = (props) => {
  const {
    images,
    likesCount,
    progressPct,
  } = props;

  const padControls = {
    left: 'medium',
    right: 'medium',
    bottom: 'medium',
  };

  return (
    <SwiperContainer>
      <Box gap="medium">
        <Box pad={padControls}>
          <ProgressBar likesCount={likesCount} progressPct={progressPct} />
        </Box>
        <ImageContainer>
          {
            images.map(img => (
              <Image
                key={img.name}
                name={img.name}
              />
            ))
          }
        </ImageContainer>
      </Box>
    </SwiperContainer>
  );
};

Swiper.propTypes = {
  images: PropTypes.arrayOf(imgType).isRequired,
  likesCount: PropTypes.number.isRequired,
  progressPct: PropTypes.number.isRequired,
};

export default Swiper;
