import React from 'react';
import { Box, Button } from 'grommet';
import PropTypes from 'prop-types';
import Container from '../Layout/Container';
import { DateRange } from '../Form';
import { SecondaryHeading } from '../Shared';


class CsvResults extends React.Component {
  constructor(props) {
    super(props);
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 7);
    this.state = {
      dates: [[
        fromDate.toISOString(),
        new Date().toISOString(),
      ]],
      date: undefined,
    };
  }

  onSubmit = () => {
    const { dates } = this.state;
    const { getResults } = this.props;
    const [[fromDate, toDate]] = dates;
    getResults(fromDate, toDate);
  }

  onSelectRange = ({ date, dates }) => {
    this.setState({ date, dates });
  }

  render() {
    const { dates, date } = this.state;
    const isValid = !!dates;
    return (
      <Container maxWidth="small">
        <Box
          pad={{
            top: 'large',
            bottom: 'large',
            left: 'medium',
            right: 'medium',
          }}
          gap="large"
        >
          <SecondaryHeading>
            Select date range to download results in CSV format:
          </SecondaryHeading>
          <Box gap="large" align="center" alignContent="center">
            <DateRange
              onSelect={this.onSelectRange}
              dates={dates}
              date={date}
            />
            <Button
              label="Download CSV"
              alignSelf="start"
              primary
              type="button"
              onClick={this.onSubmit}
              disabled={!isValid}
            />
          </Box>
        </Box>
      </Container>
    );
  }
};

CsvResults.propTypes = {
  getResults: PropTypes.func.isRequired,
};

export default CsvResults;
