
/**
 * Function that returns a new Promise, which resolves
 * after the given amount of time (in ms).
 * */
const timer = ms => new Promise(
  resolve => setTimeout(resolve, ms),
);


// eslint-disable-next-line import/prefer-default-export
export { timer };
