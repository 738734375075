import { BitlyClient } from 'bitly';

const bitly = new BitlyClient(process.env.REACT_APP_BITLY_KEY, {});

/**
 * Uses the Bitly API to shorten a link
 */
export const shortenLink = async (link) => {
  let result;
  try {
    result = await bitly.shorten(link);
  } catch (e) {
    throw e;
  }
  return result;
};

/**
 * Checks weather a given url points to the result list
 */
export const isListUrl = (url) => {
  const regexRes = /pull-all-results\/?$/.exec(url);
  return regexRes !== null;
};

/**
 * Checks weather a given url points to the result list
 */
export const isCsvUrl = (url) => {
  const regexRes = /csv-results\/?$/.exec(url);
  return regexRes !== null;
};

/**
 * Takes a url-string and returns a contained result token
 * if present, null otherwise.
 * @param {string} url
 */
export const getResultIdFromUrl = (url) => {
  const params = /\/(\w+)\/?$/.exec(url);
  if (params) {
    return params[1];
  }
  return null;
};

/**
 * Takes a result id and returns the url to access the result
 * @param {string} resultId: firebase document id
 */
export const getUrlFromResultId = (resultId) => {
  const currentUrl = window.location.href;
  const baseUrl = /^.+?[^/:](?=[?/]|$)/.exec(currentUrl)[0];
  return `${baseUrl}/${resultId}`;
};

/**
 * Takes a image file name and returns the public
 * url to the respective google cloud bucket,
 * which specified in the environment variables.
 * */
export const buildImageUrl = (name) => {
  const baseUrl = 'https://storage.googleapis.com';
  const bucketUrl = process.env.REACT_APP_STORAGE_BUCKET;
  return `${baseUrl}/${bucketUrl}/${name}`;
};

/**
 * Takes a result id and returns the url which
 * should be used to share rich media content.
 * @param {string} resultId: firebase document id
 * */
export const buildShareUrl = (resultId) => {
  const shareUrl = `https://ideate.codesignstudio.com.au/share/${resultId}`;
  return shareUrl;
};
