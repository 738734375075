import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { RESULT_COLLECTION } from './constants';
import { ErrorNotFound } from '../../helpers/error';


const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const docToResult = (docId, docData) => ({
  id: docId,
  ...docData,
});

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.db = app.firestore();
  }

  fetchResult(docId) {
    return this.db
      .collection(RESULT_COLLECTION)
      .doc(docId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const res = docToResult(doc.id, doc.data());
          return Promise.resolve(res);
        } else {
          return Promise.reject(
            new ErrorNotFound(`Document with id "${docId}" does not exist.`),
          );
        }
      });
  }

  fetchAllResults() {
    return this.db
      .collection(RESULT_COLLECTION)
      .get()
      .then((querySnapshot) => {
        const resultList = querySnapshot.docs.map(
          doc => docToResult(doc.id, doc.data()),
        );
        return Promise.resolve(resultList);
      });
  }

  fetchFilteredResults(fromDate, toDate) {
    return this.db
      .collection(RESULT_COLLECTION)
      .where('date', '>=', new Date(fromDate))
      .where('date', '<=', new Date(toDate))
      .get()
      .then((querySnapshot) => {
        const resultList = querySnapshot.docs.map(
          doc => docToResult(doc.id, doc.data()),
        );
        return Promise.resolve(resultList);
      });
  }

  saveResult(result) {
    return this.db
      .collection(RESULT_COLLECTION)
      .add(result)
      .then((doc) => {
        const res = docToResult(doc.id, result);
        return Promise.resolve(res);
      });
  }
}

export default Firebase;
