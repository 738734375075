const errorCodes = {
  NOT_FOUND: 'NOT_FOUND',
};

class ErrorNotFound extends Error {
  constructor(...params) {
    super(...params);
    this.name = errorCodes.NOT_FOUND;
  }
}

export { errorCodes, ErrorNotFound };
