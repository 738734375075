import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Form, FormField, Text, TextInput } from 'grommet';
import { areaArrayType } from '../../helpers/propTypes';
import { AnimatedBox } from '../Layout';
import AreaFields from './AreaFields';
import Intro from './Intro';
import { LabelText, SecondaryHeading } from '../Shared';

const StyledFormField = styled(FormField)`
  div {
    border-bottom-color: #DADADA;
  }
`;

const SurveyForm = (
  {
    areas1,
    areas2,
    result,
    handleChange,
    errors,
    page,
  },
) => (
  <Form>
    <Box gap="large">
      {page === 1 && (
        <Box gap="medium">
          <Intro />
          <StyledFormField
            label={<LabelText>Enter your location</LabelText>}
            error={errors.location}
          >
            <TextInput
              name="location"
              value={result.location}
              onChange={handleChange}
              placeholder="Suburb or city and State"
              focusIndicator
            />
          </StyledFormField>
          <StyledFormField
            label={<LabelText>Enter your email address</LabelText>}
            error={errors.email}
          >
            <TextInput
              name="email"
              value={result.email}
              onChange={handleChange}
              placeholder="Email"
            />
          </StyledFormField>
        </Box>
      )}
      {[2, 3].includes(page) && (
        <Box
          pad="small"
          responsive={false}
          gap="large"
        >
          <Box gap="small">
            <SecondaryHeading>
              Thanks!
            </SecondaryHeading>
            <Text>
              As we begin, take a moment to close your eyes and picture your neighbourhood.
              What does it look like? What does it feel like? What emotions do you feel?
            </Text>
            <Text>
              Now open your eyes, and consider:
            </Text>
          </Box>
          { page === 2 && (
            <AnimatedBox
              type="fadeIn"
            >
              <StyledFormField
                label={<LabelText>What is missing in your neighbourhood?</LabelText>}
                error={errors.areas1}
              >
                <AreaFields
                  name="areas1"
                  areas={areas1}
                  selection={result.areas1}
                  handleChange={handleChange}
                />
              </StyledFormField>
            </AnimatedBox>
          )}
          { page === 3 && (
            <AnimatedBox
              type="fadeIn"
            >
              <StyledFormField
                label={(
                  <LabelText>
                    What are the top needs you see, and hear about, in your community?
                  </LabelText>
                )}
                error={errors.areas2}
              >
                <AreaFields
                  name="areas2"
                  areas={areas2}
                  selection={result.areas2}
                  handleChange={handleChange}
                />
              </StyledFormField>
            </AnimatedBox>
          )}
        </Box>
      )}
    </Box>
  </Form>
);

SurveyForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  result: PropTypes.object.isRequired,
  areas1: areaArrayType.isRequired,
  areas2: areaArrayType.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
};

export default SurveyForm;
