import { Text } from 'grommet/es6';
import React from 'react';
import PropTypes from 'prop-types';

const LabelText = ({ children }) => (
  <Text
    size="medium"
    color="brand"
    style={{
      fontFamily: 'Brandon Grotesque',
      textTransform: 'uppercase',
    }}
  >
    {children}
  </Text>
);

LabelText.propTypes = {
  children: PropTypes.node.isRequired,
};


export default LabelText;
