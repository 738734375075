import { Box, Button } from 'grommet/es6';
import { Dislike, Like } from 'grommet-icons/es6';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const SwiperButton = styled(Button)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const SwiperButtons = (props) => {
  const { handleClickNo, handleClickYes } = props;

  return (
    <Box
      direction="row"
      gap="small"
      width="480px"
    >
      <SwiperButton
        label={<Dislike color="white" />}
        onClick={handleClickNo}
        primary
      />
      <SwiperButton
        label={<Like color="white" />}
        onClick={handleClickYes}
        primary
      />
    </Box>
  );
};

SwiperButtons.propTypes = {
  handleClickNo: PropTypes.func.isRequired,
  handleClickYes: PropTypes.func.isRequired,
};


export default SwiperButtons;
