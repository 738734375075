import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.global.colors['light-2']};
`;

const AspectRatioContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; // 16:9
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;


const YouTube = ({ id, maxWidth }) => (
  <Wrapper maxWidth={maxWidth}>
    <AspectRatioContainer>
      <StyledIframe
        title={`Embedded YouTube video ${id}`}
        src={`https://www.youtube.com/embed/${id}`}
        frameBorder="0"
      />
    </AspectRatioContainer>
  </Wrapper>
);

YouTube.defaultProps = {
  maxWidth: 'none',
};

YouTube.propTypes = {
  id: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
};

export default YouTube;
