import React from 'react';
import PropType from 'prop-types';
import { Box } from 'grommet';
import {
  getUrlFromResultId, buildShareUrl,
  shortenLink,
} from '../../helpers/url';
import Container from '../Layout/Container';
import { resultType } from '../../helpers/propTypes';
import IntroText from './IntroText';
import OutroText from './OutroText';
import Projects from './Projects';
import Images from './Images';
import Share from './Share';
import { SecondaryHeading } from '../Shared';

class Result extends React.Component {
  constructor(props) {
    super(props);
    const {
      result: {
        id,
      },
    } = props;

    this.state = {
      shortenedUrl: null,
      resultUrl: getUrlFromResultId(id),
      shareUrl: buildShareUrl(id),
    };

    const { shareUrl } = this.state;

    shortenLink(shareUrl)
      .then((x) => {
        this.setState({ shortenedUrl: x.url });
      });
  }


  render() {
    const {
      result: {
        images, projects,
      },
      children,
    } = this.props;

    const { shortenedUrl, resultUrl, shareUrl } = this.state;
    const richMediaShareUrl = `${shareUrl}?shorturl=${encodeURIComponent(shortenedUrl)}`;

    return (
      <Container maxWidth="small">
        <Box
          pad={{
            top: 'large',
            bottom: 'large',
            left: 'medium',
            right: 'medium',
          }}
          gap="large"
        >
          <IntroText />
          {
            images && <Images images={images} />
          }
          {
            projects && (
              <Box gap="medium">
                <SecondaryHeading>
                  Top 3 placemaking projects for my community:
                </SecondaryHeading>
                <Projects projects={projects} />
              </Box>
            )
          }
          {
            shortenedUrl && (
              <Share
                url={resultUrl}
                shortenedUrl={shortenedUrl}
                shareUrl={richMediaShareUrl}
              />
            )
          }
          <OutroText />
          {children}
        </Box>
      </Container>
    );
  }
}

Result.propTypes = {
  result: resultType.isRequired,
  children: PropType.node.isRequired,
};

export default Result;
