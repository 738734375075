import React from 'react';
import PropTypes from 'prop-types';

import { resultType } from '../../helpers/propTypes';

const ResultList = ({ results }) => (
  <div>
    {
      results.map(res => (
        <div key={res.id}>
          <pre style={{ width: '100%' }}>
            {JSON.stringify(res, undefined, 2)}
          </pre>
        </div>
      ))
    }
  </div>
);

ResultList.propTypes = {
  results: PropTypes.arrayOf(resultType).isRequired,
};

export default ResultList;
