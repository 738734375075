import React from 'react';
import PropTypes from 'prop-types';
import { buildImageUrl } from '../../helpers/url';

const Image = (props) => {
  const { name, className } = props;
  const url = buildImageUrl(name);

  return (
    <img src={url} alt={name} className={className} />
  );
};

Image.defaultProps = {
  className: null,
};


Image.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Image;
