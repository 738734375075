import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { imgType } from '../../helpers/propTypes';
import ImagePickerItem from './ImagePickerItem';


const gap = 5;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -${gap}px;
  margin-left: -${gap}px;
`;

const GridItem = styled.div`
  flex-basis: 33.33333%;
  flex-grow: 0;
  box-sizing: border-box;
  padding-top: ${gap}px;
  padding-left: ${gap}px;
    
  @media(max-width: ${({ theme }) => theme.global.breakpoints.xsmall.value}px) {
    flex-basis: 50%;
  }
`;


const ImagePicker = (props) => {
  const { images, selection, onSelect } = props;

  return (
    <Grid>
      {
        images.map(img => (
          <GridItem key={img.name}>
            <ImagePickerItem
              image={img}
              isSelected={selection.has(img)}
              onSelect={onSelect}
            />
          </GridItem>
        ))
      }
    </Grid>
  );
};

ImagePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object.isRequired, // is actually a set
  images: PropTypes.arrayOf(imgType).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ImagePicker;
