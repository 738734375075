import React from 'react';
import { Button, Text } from 'grommet';


const ErrorNotFound = () => (
  <React.Fragment>
    <Text>
      What ever you are looking for does not exist anymore...
      <br />
      Why don&apos;t you just create something new?
    </Text>
    <br />
    <Button
      href="/"
      label="Sure, let's get started!"
      alignSelf="start"
      primary
    />
  </React.Fragment>
);


export default ErrorNotFound;
