import React from 'react';
import styled, { css } from 'styled-components';
import { Checkmark } from 'grommet-icons/es6';
import PropTypes from 'prop-types';
import Image from '../Image';
import { imgType } from '../../helpers/propTypes';
import { position } from '../../helpers/style';
import { buildKeyPressHandler } from '../../helpers/event';


const ImageWrapper = styled.div`
  position: absolute;
  
  ${position(0)};
  transition: all 0.07s ease-out;
  
  ${({ isSelected }) => isSelected && css`
    ${position('10px')};
  `}
  
  // color overlay
  &:before {
    content: '';
    position: absolute;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: none;
    background-color: ${({ theme }) => theme.global.colors['accent-1']};
    opacity: ${({ isSelected }) => (isSelected ? 0.5 : 0)};
    pointer-events: none;
    z-index: 1;
    ${({ isSelected }) => isSelected && css`
      opacity: 0.7;
      transition: opacity 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    `}
  }
`;

const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
`;

const IconContainer = styled.div`
  position: absolute;
  ${position(0)};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    // rotate on select
    transform-style: preserve-3d;
    transition: none;
    transform: translateY(20px) rotateY(-20deg) rotateZ(-45deg) scale(0.2) ;
    transform-origin: center;
    opacity: 0;
    ${({ isSelected }) => isSelected && css`
      opacity: 1;
      transform: translate3d(0, 0 , 0);
      transition: all 0.15s ease-out;
    `}
  }
`;

const ClickContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-top: 100%;
  cursor: pointer;
  
  &:focus {
    outline: none;
    ${ImageWrapper} {
       ${position('10px')};
    }
  }
`;


const ImagePickerItem = ({ image, isSelected, onSelect }) => {
  const onClick = (e) => {
    e.preventDefault();
    onSelect(image);
  };
  return (
    <ClickContainer
      onClick={onClick}
      onKeyPress={buildKeyPressHandler([13, 32], onClick)}
      tabIndex={0}
    >
      <ImageWrapper
        isSelected={isSelected}
      >
        <StyledImage
          name={image.name}
        />
        <IconContainer
          isSelected={isSelected}
        >
          <Checkmark
            size="large"
            color="white"
          />
        </IconContainer>
      </ImageWrapper>
    </ClickContainer>
  );
};

ImagePickerItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  image: imgType.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ImagePickerItem;
