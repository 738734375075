export default [
  {
    name: '98075906.jpg',
    tags: [
      'indigenous',
      'arts',
      'heritage',
      'mural',
      'culturalconnection',
      'painting',
      'creative',
      'beautification',
      'pride',
      'history',
      'reflection',
    ],
  },
  {
    name: '153876660.jpg',
    tags: [
      'localtrade',
      'nature',
      'market',
      'preserves',
      'creative',
      'fundraising',
      'diy',
      'ecofriendly',
    ],
  },
  {
    name: '155430681.jpg',
    tags: [
      'learning',
      'outdoor',
      'nature',
      'groups',
      'activity',
      'trees',
      'tours',
      'laughing',
      'belonging',
      'storytelling',
      'environment',
      'childfriendly',
      'health',
      'schools',
      'summer',
    ],
  },
  {
    name: '173635754.jpg',
    tags: [
      'bushwalk',
      'nature',
      'birdwatching',
      'walking',
      'outdoor',
      'park',
    ],
  },
  {
    name: '175175106.jpg',
    tags: [
      'chalk',
      'pavement',
      'drawing',
      'kids',
      'creative',
      'sparetime',
      'outdoor',
      'signage',
      'beautification',
    ],
  },
  {
    name: '177520548.jpg',
    tags: [
      'outdoor',
      'creative',
      'activity',
      'beautification',
      'painting',
      'instrument',
      'lingernode',
      'music',
      'diy',
      'colourful',
    ],
  },
  {
    name: '465701503.jpg',
    tags: [
      'chalk',
      'kids',
      'vibrancy',
      'arts',
      'drawing',
      'outdoor',
      'activity',
      'beautification',
      'creative',
    ],
  },
  {
    name: '466499123.jpg',
    tags: [
      'accessibility',
      'signage',
      'reading',
      'winter',
      'autumn',
      'learning',
      'disability',
      'noticeboard',
      'safety',
    ],
  },
  {
    name: '471114276.jpg',
    tags: [
      'environment',
      'active',
      'childfriendly',
      'play',
      'games',
      'mud',
      'nature',
      'trees',
    ],
  },
  {
    name: '471217010.jpg',
    tags: [
      'vegetables',
      'market',
      'kids',
      'green',
      'popup',
      'shopping',
      'multigenerational',
      'preserves',
      'environment',
      'festival',
      'wellbeing',
      'childfriendly',
      'daytime',
    ],
  },
  {
    name: '478438526.jpg',
    tags: [
      'languages',
      'learning',
      'multicultural',
      'education',
      'meet',
      'activity',
      'skillsharing',
      'signage',
      'writing',
      'noticeboard',
    ],
  },
  {
    name: '486639328.jpg',
    tags: [
      'heritage',
      'identity',
      'events',
      'multicultural',
      'culturalconnection',
      'lights',
      'festival',
      'history',
      'night',
      'performance',
    ],
  },
  {
    name: '489119482.jpg',
    tags: [
      'bus',
      'publictransport',
      'travel',
      'street',
      'youth',
      'multicultural',
      'adventure',
      'autumn',
      'lingernode',
    ],
  },
  {
    name: '492960428.jpg',
    tags: [
      'making',
      'activity',
      'arts',
      'beautification',
      'colourful',
      'creative',
    ],
  },
  {
    name: '493887232.jpg',
    tags: [
      'kids',
      'learning',
      'play',
      'education',
      'schools',
      'making',
      'socialconnection',
      'activity',
      'groups',
      'resourcesharing',
    ],
  },
  {
    name: '494182640.jpg',
    tags: [
      'kids',
      'painting',
      'vibrancy',
      'mural',
      'beautification',
      'streetart',
      'youth',
      'meet',
      'activity',
      'signage',
      'creative',
      'multigenerational',
      'diy',
      'childfriendly',
      'colourful',
    ],
  },
  {
    name: '499373254.jpg',
    tags: [
      'gather',
      'bookclub',
      'books',
      'reading',
      'groups',
      'activity',
      'socialconnection',
      'meet',
      'multicultural',
      'education',
      'storytelling',
      'network',
      'home',
      'belonging',
      'history',
    ],
  },
  {
    name: '509722755.jpg',
    tags: [
      'bicycle',
      'exercise',
      'activity',
      'outdoor',
      'competition',
      'events',
      'sports',
      'publictransport',
      'active',
      'summer',
    ],
  },
  {
    name: '512210626.jpg',
    tags: [
      'exercise',
      'parents',
      'home',
      'health',
      'play',
      'winter',
      'sports',
      'multigenerational',
      'accessibility',
      'active',
      'wellbeing',
      'childfriendly',
      'reflection',
    ],
  },
  {
    name: '513230376.jpg',
    tags: [
      'kayaking',
      'adventure',
      'nature',
      'outdoor',
      'exercise',
      'travel',
      'sparetime',
      'sports',
      'activity',
      'environment',
      'active',
      'health',
    ],
  },
  {
    name: '513546194.jpg',
    tags: [
      'exercise',
      'colourful',
      'events',
      'active',
      'activity',
      'sports',
      'youth',
      'groups',
      'creative',
      'volunteer',
      'festival',
      'belonging',
      'socialconnection',
    ],
  },
  {
    name: '519362633.jpg',
    tags: [
      'chalk',
      'arts',
      'drawing',
      'kids',
      'summer',
      'play',
      'creative',
      'outdoor',
      'activity',
      'childfriendly',
    ],
  },
  {
    name: '527086325.jpg',
    tags: [
      'tasting',
      'localtrade',
      'drinks',
      'instagram',
      'festival',
      'adult',
      'night',
    ],
  },
  {
    name: '591834548.jpg',
    tags: [
      'outdoor',
      'running',
      'exercise',
      'groups',
      'sports',
      'activity',
      'active',
      'warm',
      'health',
      'pavement',
      'safety',
    ],
  },
  {
    name: '597952626.jpg',
    tags: [
      'nature',
      'birdwatching',
      'green',
      'outdoor',
      'youth',
      'photography',
      'activity',
      'environment',
      'active',
      'adventure',
      'bushwalk',
      'trees',
    ],
  },
  {
    name: '605776386.jpg',
    tags: [
      'books',
      'bookclub',
      'park',
      'littlelibrary',
      'resourcesharing',
      'reading',
      'beautification',
      'volunteer',
      'multigenerational',
      'diy',
      'noticeboard',
    ],
  },
  {
    name: '610144422.jpg',
    tags: [
      'running',
      'walking',
      'exercise',
      'groups',
      'outdoor',
      'belonging',
      'socialconnection',
      'meet',
      'sports',
      'youth',
      'network',
      'active',
      'colourful',
      'health',
      'safety',
    ],
  },
  {
    name: '610259354.jpg',
    tags: [
      'photography',
      'instagram',
      'winter',
      'autumn',
      'adventure',
      'travel',
      'lingernode',
      'safety',
    ],
  },
  {
    name: '615604320.jpg',
    tags: [
      'resourcesharing',
      'littlelibrary',
      'books',
      'kids',
      'park',
      'bookclub',
      'reading',
      'education',
      'volunteer',
      'childfriendly',
      'colourful',
      'daytime',
    ],
  },
  {
    name: '625755134.jpg',
    tags: [
      'kids',
      'learning',
      'skillsharing',
      'play',
      'education',
      'schools',
      'childfriendly',
    ],
  },
  {
    name: '638778288.jpg',
    tags: [
      'fashion',
      'localtrade',
      'indoor',
      'creative',
      'shopping',
      'business',
      'youth',
      'active',
    ],
  },
  {
    name: '639534022.jpg',
    tags: [
      'pottery',
      'localtrade',
      'business',
      'arts',
      'making',
      'resourcesharing',
      'creative',
      'daytime',
      'shopping',
    ],
  },
  {
    name: '654361262.jpg',
    tags: [
      'build',
      'activity',
      'mechanic',
      'sparetime',
      'tools',
      'cars',
      'vintage',
      'repairs',
    ],
  },
  {
    name: '658606704.jpg',
    tags: [
      'drinks',
      'tasting',
      'wine',
      'adult',
      'activity',
      'travel',
      'skillsharing',
      'fashion',
    ],
  },
  {
    name: '686890156.jpg',
    tags: [
      'kids',
      'play',
      'activity',
      'childfriendly',
      'family',
      'games',
      'parents',
      'yards',
      'park',
      'sports',
      'festival',
      'active',
      'colourful',
    ],
  },
  {
    name: '822238458.jpg',
    tags: [
      'reading',
      'books',
      'languages',
      'learning',
      'sparetime',
      'writing',
    ],
  },
  {
    name: '841383226.jpg',
    tags: [
      'drinks',
      'creative',
      'adult',
      'localtrade',
      'instagram',
      'activity',
      'skillsharing',
      'festival',
      'night',
    ],
  },
  {
    name: '860026356.jpg',
    tags: [
      'food',
      'gather',
      'socialconnection',
      'meals',
      'resourcesharing',
      'vegetables',
      'green',
      'instagram',
      'friends',
      'meet',
      'groups',
      'multigenerational',
      'pride',
      'belonging',
      'health',
    ],
  },
  {
    name: '860943666.jpg',
    tags: [
      'accessibility',
      'build',
      'communitygarden',
      'mural',
      'diy',
      'socialconnection',
      'meet',
      'skillsharing',
      'resourcesharing',
      'ecofriendly',
      'belonging',
      'disability',
    ],
  },
  {
    name: '868925192.jpg',
    tags: [
      'build',
      'making',
      'mechanic',
      'skillsharing',
      'diy',
    ],
  },
  {
    name: '889695434.jpg',
    tags: [
      'beekeeping',
      'communitygarden',
      'gardening',
      'localtrade',
      'nature',
      'activity',
      'daytime',
    ],
  },
  {
    name: '892366948.jpg',
    tags: [
      'lights',
      'festival',
      'gather',
      'night',
      'outdoor',
      'cold',
      'winter',
      'autumn',
      'family',
      'belonging',
      'socialconnection',
      'youth',
      'groups',
      'pride',
    ],
  },
  {
    name: '899764414.jpg',
    tags: [
      'market',
      'creative',
      'pottery',
      'arts',
      'popup',
      'colourful',
      'beautification',
      'fundraising',
      'multigenerational',
    ],
  },
  {
    name: '899827694.jpg',
    tags: [
      'business',
      'localtrade',
      'socialconnection',
      'groups',
      'meet',
      'network',
      'drinks',
      'adult',
      'sparetime',
      'summer',
      'spring',
      'belonging',
      'events',
      'fundraising',
      'volunteer',
      'fashion',
      'night',
    ],
  },
  {
    name: '901570694.jpg',
    tags: [
      'streetparty',
      'popup',
      'events',
      'family',
      'youth',
      'fundraising',
      'volunteer',
      'festival',
      'childfriendly',
      'market',
    ],
  },
  {
    name: '907944624.jpg',
    tags: [
      'park',
      'parents',
      'family',
      'nature',
      'outdoor',
      'sports',
      'activity',
      'volunteer',
      'environment',
      'active',
      'childfriendly',
    ],
  },
  {
    name: '911274748.jpg',
    tags: [
      'communitygarden',
      'gardening',
      'green',
      'vegetables',
      'winter',
      'home',
      'yards',
      'outdoor',
      'volunteer',
      'diy',
      'environment',
      'childfriendly',
    ],
  },
  {
    name: '912752194.jpg',
    tags: [
      'youth',
      'learning',
      'adventure',
      'winter',
      'education',
      'books',
      'sparetime',
      'culturalconnection',
      'publictransport',
      'belonging',
      'writing',
      'lingernode',
      'reflection',
    ],
  },
  {
    name: '932186624.jpg',
    tags: [
      'play',
      'nature',
      'kids',
      'competition',
      'mud',
      'activity',
      'active',
      'childfriendly',
      'trees',
    ],
  },
  {
    name: '935648828.jpg',
    tags: [
      'nature',
      'travel',
      'adventure',
      'youth',
      'active',
      'bushwalk',
      'daytime',
      'belonging',
      'meet',
      'sports',
      'walking',
      'health',
    ],
  },
  {
    name: '938119414.jpg',
    tags: [
      'arts',
      'learning',
      'youth',
      'creative',
      'drawing',
      'painting',
      'activity',
      'meet',
      'education',
      'signage',
      'beautification',
    ],
  },
  {
    name: '938464238.jpg',
    tags: [
      'youth',
      'multigenerational',
      'volunteer',
      'meet',
      'home',
      'tea',
      'indoor',
      'activity',
      'storytelling',
      'skillsharing',
      'olderpeople',
      'accessibility',
      'health',
    ],
  },
  {
    name: '941272888.jpg',
    tags: [
      'indoor',
      'knitting',
      'creative',
      'home',
      'winter',
      'autumn',
      'cosy',
      'activity',
      'skillsharing',
      'beautification',
      'multigenerational',
    ],
  },
  {
    name: '942616500.jpg',
    tags: [
      'dogs',
      'park',
      'outdoor',
      'active',
      'nature',
      'sparetime',
      'socialconnection',
      'safety',
    ],
  },
  {
    name: '948065772.jpg',
    tags: [
      'parents',
      'kids',
      'cooking',
      'food',
      'vegetables',
      'home',
      'family',
      'cosy',
      'winter',
      'skillsharing',
      'volunteer',
      'resourcesharing',
      'ecofriendly',
      'environment',
      'warm',
      'childfriendly',
      'health',
    ],
  },
  {
    name: '948628914.jpg',
    tags: [
      'business',
      'groups',
      'indoor',
      'coffee',
      'winter',
      'friends',
      'conversations',
      'belonging',
      'socialconnection',
      'meet',
      'network',
      'safety',
    ],
  },
  {
    name: '950590446.jpg',
    tags: [
      'gardening',
      'localtrade',
      'communitygarden',
      'creative',
      'youth',
      'sparetime',
      'activity',
      'skillsharing',
      'beautification',
      'fundraising',
      'resourcesharing',
      'multigenerational',
      'diy',
      'environment',
      'flowers',
    ],
  },
  {
    name: '950671616.jpg',
    tags: [
      'build',
      'bicycle',
      'cars',
      'tools',
      'repairs',
    ],
  },
  {
    name: '952414774.jpg',
    tags: [
      'beer',
      'drinks',
      'meet',
      'gather',
      'localtrade',
      'adult',
      'friends',
      'laughing',
      'conversations',
      'socialconnection',
      'belonging',
    ],
  },
  {
    name: '954596030.jpg',
    tags: [
      'wellbeing',
      'meditation',
      'yoga',
      'sports',
      'activity',
      'home',
      'active',
      'health',
      'reflection',
      'safety',
    ],
  },
  {
    name: '955641488.jpg',
    tags: [
      'fashion',
      'mainstreet',
      'localtrade',
      'shopping',
      'business',
      'vintage',
    ],
  },
  {
    name: '957031184.jpg',
    tags: [
      'build',
      'outdoor',
      'making',
      'summer',
      'tools',
      'trees',
      'skillsharing',
      'signage',
      'volunteer',
      'resourcesharing',
      'pride',
      'diy',
      'safety',
    ],
  },
  {
    name: '959670488.jpg',
    tags: [
      'instrument',
      'arts',
      'music',
      'creative',
      'performance',
      'theatre',
      'busking',
      'learning',
      'activity',
    ],
  },
  {
    name: '960753762.jpg',
    tags: [
      'arts',
      'creative',
      'outdoor',
      'park',
      'adult',
      'painting',
      'drawing',
      'environment',
      'accessibility',
      'reflection',
    ],
  },
  {
    name: '963418362.jpg',
    tags: [
      'streetart',
      'pavement',
      'beautification',
      'mural',
      'chalk',
      'creative',
      'publicspace',
      'pride',
      'flowers',
      'history',
    ],
  },
  {
    name: '980494376.jpg',
    tags: [
      'film',
      'outdoorcinema',
      'olderpeople',
      'theatre',
      'sparetime',
      'activity',
      'multigenerational',
      'night',
      'performance',
    ],
  },
  {
    name: '982746520.jpg',
    tags: [
      'film',
      'storytelling',
      'photography',
      'creative',
      'winter',
      'cold',
      'activity',
      'youth',
    ],
  },
  {
    name: '984761732.jpg',
    tags: [
      'film',
      'popup',
      'events',
      'gather',
      'socialconnection',
      'culturalconnection',
      'pride',
      'vibrancy',
      'multigenerational',
      'festival',
      'safety',
    ],
  },
  {
    name: '986729140.jpg',
    tags: [
      'birdwatching',
      'nature',
      'bushwalk',
      'outdoor',
      'warm',
      'meet',
      'activity',
      'olderpeople',
      'active',
      'tours',
    ],
  },
  {
    name: '996097046.jpg',
    tags: [
      'adult',
      'cooking',
      'learning',
      'meet',
      'home',
      'summer',
      'wine',
    ],
  },
  {
    name: '997359378.jpg',
    tags: [
      'baking',
      'gather',
      'meet',
      'tea',
      'instagram',
      'preserves',
    ],
  },
  {
    name: '998968848.jpg',
    tags: [
      'drinks',
      'adult',
      'summer',
      'laughing',
      'gather',
      'festival',
      'tours',
    ],
  },
  {
    name: '1005633958.jpg',
    tags: [
      'baking',
      'home',
      'food',
      'resourcesharing',
      'instagram',
      'cooking',
    ],
  },
  {
    name: '1005735718.jpg',
    tags: [
      'reading',
      'home',
      'books',
      'cosy',
      'winter',
      'tea',
      'coffee',
      'sparetime',
      'creative',
      'safety',
    ],
  },
  {
    name: '1006503210.jpg',
    tags: [
      'food',
      'localtrade',
      'popup',
      'park',
      'market',
      'foodtruck',
      'lights',
      'signage',
      'beautification',
    ],
  },
  {
    name: '1010884084.jpg',
    tags: [
      'gather',
      'groups',
      'home',
      'indoor',
      'friends',
      'sports',
      'activity',
      'youth',
      'film',
      'autumn',
      'competition',
      'night',
      'safety',
    ],
  },
  {
    name: '1019786062.jpg',
    tags: [
      'coffee',
      'digital',
      'indoor',
      'instagram',
      'photography',
      'youth',
      'skillsharing',
      'noticeboard',
    ],
  },
  {
    name: '1023421012.jpg',
    tags: [
      'competition',
      'active',
      'olderpeople',
      'running',
      'events',
      'outdoor',
      'warm',
      'sports',
      'fundraising',
      'multigenerational',
      'pride',
      'health',
    ],
  },
  {
    name: '1025929842.jpg',
    tags: [
      'busking',
      'street',
      'mainstreet',
      'streetparty',
      'music',
      'summer',
      'spring',
      'outdoor',
      'events',
      'youth',
      'culturalconnection',
      'festival',
      'performance',
    ],
  },
  {
    name: '1026367516.jpg',
    tags: [
      'health',
      'meet',
      'learning',
      'resourcesharing',
      'skillsharing',
      'education',
    ],
  },
  {
    name: '1035286028.jpg',
    tags: [
      'dogs',
      'park',
      'active',
      'cold',
      'winter',
      'exercise',
      'autumn',
      'sports',
    ],
  },
  {
    name: '1035803962.jpg',
    tags: [
      'accessibility',
      'parkbench',
      'conversations',
      'belonging',
      'volunteer',
      'publictransport',
      'disability',
      'laughing',
      'lingernode',
    ],
  },
  {
    name: '1036815628.jpg',
    tags: [
      'meals',
      'food',
      'home',
      'cooking',
      'cosy',
      'resourcesharing',
      'multigenerational',
      'health',
    ],
  },
  {
    name: '1041987488.jpg',
    tags: [
      'dogs',
      'meet',
      'play',
      'park',
      'warm',
      'environment',
      'active',
      'lingernode',
      'safety',
    ],
  },
  {
    name: '1046366136.jpg',
    tags: [
      'food',
      'meals',
      'localtrade',
      'foodtruck',
      'beer',
      'drinks',
      'instagram',
      'youth',
    ],
  },
  {
    name: '1055293068.jpg',
    tags: [
      'outdoorcinema',
      'gather',
      'popup',
      'lights',
      'diy',
      'yards',
      'cosy',
      'friends',
      'activity',
      'youth',
      'festival',
      'film',
      'belonging',
      'night',
    ],
  },
  {
    name: '1059735838.jpg',
    tags: [
      'mud',
      'nature',
      'play',
      'kids',
      'park',
      'outdoor',
      'environment',
      'active',
      'childfriendly',
    ],
  },
  {
    name: '1069650938.jpg',
    tags: [
      'knitting',
      'mainstreet',
      'trees',
      'creative',
      'beautification',
      'diy',
      'lingernode',
    ],
  },
  {
    name: '1070269012.jpg',
    tags: [
      'park',
      'play',
      'kids',
      'outdoor',
      'cold',
      'winter',
      'nature',
      'trees',
      'family',
      'active',
      'autumn',
      'childfriendly',
      'daytime',
    ],
  },
  {
    name: '1070364492.jpg',
    tags: [
      'streetparty',
      'publicspace',
      'pride',
      'family',
      'multigenerational',
      'play',
      'meet',
      'events',
      'active',
      'belonging',
    ],
  },
  {
    name: '1070729494.jpg',
    tags: [
      'beer',
      'creative',
      'localtrade',
      'business',
      'meet',
      'multicultural',
      'adult',
    ],
  },
  {
    name: '1072578026.jpg',
    tags: [
      'coffee',
      'drinks',
      'instagram',
      'foodtruck',
      'conversations',
      'autumn',
    ],
  },
  {
    name: '1076877778.jpg',
    tags: [
      'games',
      'play',
      'activity',
      'gather',
      'youth',
      'childfriendly',
    ],
  },
  {
    name: '1080548636.jpg',
    tags: [
      'painting',
      'olderpeople',
      'groups',
      'learning',
      'skillsharing',
      'socialconnection',
      'meet',
      'belonging',
      'activity',
      'beautification',
    ],
  },
  {
    name: '1090336532.jpg',
    tags: [
      'indigenous',
      'culturalconnection',
      'heritage',
      'identity',
      'events',
      'youth',
      'multicultural',
      'multigenerational',
      'belonging',
      'history',
      'performance',
    ],
  },
  {
    name: '1094085124.jpg',
    tags: [
      'mural',
      'arts',
      'streetart',
      'culturalconnection',
      'creative',
      'beautification',
      'festival',
      'tours',
    ],
  },
  {
    name: '1095066488.jpg',
    tags: [
      'bookclub',
      'olderpeople',
      'home',
      'wine',
      'reading',
      'gather',
      'socialconnection',
      'groups',
      'belonging',
      'conversations',
      'safety',
    ],
  },
  {
    name: '1095973668.jpg',
    tags: [
      'wine',
      'tasting',
      'outdoor',
      'adult',
      'adventure',
      'travel',
      'friends',
      'spring',
      'summer',
      'socialconnection',
      'meet',
      'belonging',
      'daytime',
    ],
  },
  {
    name: '1132066731.jpg',
    tags: [
      'kids',
      'learning',
      'multicultural',
      'indoor',
      'schools',
      'play',
      'making',
      'books',
      'education',
      'resourcesharing',
      'childfriendly',
    ],
  },
  {
    name: '1135794894.jpg',
    tags: [
      'pottery',
      'learning',
      'olderpeople',
      'creative',
      'making',
      'activity',
      'multigenerational',
    ],
  },
  {
    name: '1136230389.jpg',
    tags: [
      'bicycle',
      'parents',
      'kids',
      'build',
      'park',
      'outdoor',
      'cold',
      'winter',
      'sparetime',
      'adventure',
      'repairs',
      'meet',
      'sports',
      'events',
      'volunteer',
      'multigenerational',
      'pride',
      'childfriendly',
    ],
  },
  {
    name: '1136600322.jpg',
    tags: [
      'indoor',
      'reading',
      'digital',
      'kids',
      'childfriendly',
      'lights',
      'lingernode',
      'night',
      'safety',
    ],
  },
  {
    name: '1140641838.jpg',
    tags: [
      'yoga',
      'outdoor',
      'groups',
      'exercise',
      'wellbeing',
      'meet',
      'sports',
      'events',
      'activity',
      'active',
      'belonging',
      'health',
    ],
  },
  {
    name: '1147357700.jpg',
    tags: [
      'books',
      'market',
      'vintage',
      'winter',
      'reading',
      'events',
      'resourcesharing',
      'multigenerational',
      'writing',
    ],
  },
  {
    name: '1150730526.jpg',
    tags: [
      'indigenous',
      'culturalconnection',
      'heritage',
      'arts',
      'history',
    ],
  },
  {
    name: 'Valli-cafe-break-xxl.jpg',
    tags: [
      'coffee',
      'signage',
      'network',
      'business',
      'daytime',
      'digital',
      'noticeboard',
    ],
  },
  {
    name: 'Valli-colorful-chinese-lanterns-xxl.jpg',
    tags: [
      'colourful',
      'lights',
      'streetparty',
      'vibrancy',
      'events',
      'creative',
      'beautification',
      'diy',
      'festival',
      'lingernode',
    ],
  },
  {
    name: 'Valli-dog-walks-down-street-l.jpg',
    tags: [
      'dogs',
      'walking',
      'market',
      'street',
      'active',
      'daytime',
      'pavement',
    ],
  },
  {
    name: 'Valli-flowers-in-market-xl.jpg',
    tags: [
      'flowers',
      'gardening',
      'market',
      'vibrancy',
      'colourful',
      'creative',
      'beautification',
      'environment',
      'lingernode',
    ],
  },
  {
    name: 'Valli-lavender-flowers-xl.jpg',
    tags: [
      'beekeeping',
      'gardening',
      'spring',
      'outdoor',
      'ecofriendly',
      'environment',
      'flowers',
      'nature',
    ],
  },
  {
    name: 'Valli-street-artist-with-his-guitar-l.jpg',
    tags: [
      'music',
      'instrument',
      'street',
      'busking',
      'activity',
      'youth',
      'skillsharing',
      'creative',
      'performance',
    ],
  },
  {
    name: 'Valli-woman-dancing-xxl.jpg',
    tags: [
      'play',
      'street',
      'music',
      'friends',
      'active',
      'dancing',
      'lingernode',
      'safety',
    ],
  },
  {
    name: 'Valli-young-multiethnic-friends-sitting-on-the-floor-chatting-xxl.jpg',
    tags: [
      'youth',
      'conversations',
      'friends',
      'sparetime',
      'socialconnection',
      'storytelling',
      'groups',
      'volunteer',
      'publictransport',
      'belonging',
      'daytime',
      'safety',
    ],
  },
];
