import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { areaArrayType } from '../../helpers/propTypes';
import { Container } from '../Layout';
import SurveyForm from './SurveyForm';
import { SubmitButton } from '../Shared';
import Controls from '../Controls';


class StepSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: {
        location: '',
        email: '',
        areas1: new Set(),
        areas2: new Set(),
      },
      errors: {},
      isSubmitted: false,
      page: 1,
    };
  }

  /**
   * Takes an input name and value and returns
   * either null if the value is valid or an error
   * message if not.
   * */
  validate = (name, value) => {
    switch (name) {
      case 'areas1':
      case 'areas2':
        if (value.size === 0) {
          return 'Please select at least one area';
        }
        break;
      case 'email':
        if (!value || value.trim() === '') {
          return 'This field is required';
          // eslint-disable-next-line no-useless-escape
        } else if (!value.match(/[^@]+@[^\.]+\..+/)) {
          return 'Please enter a valid email address';
        }
        break;
      case 'location':
        if (!value || value.trim() === '') {
          return 'This field is required';
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(`Unknown field: ${name}.`);
    }
    // field is valid
    return null;
  };

  /**
   * Creates a new error object by running `validate`
   * for all properties of the given result object.
   * */
  validateAll = result => (
    Object.keys(result)
      .reduce((errors, name) => {
        const value = result[name];
        // eslint-disable-next-line no-param-reassign
        errors[name] = this.validate(name, value);
        return errors;
      }, {})
  );

  /**
   * Checks if all values of an error objects are null
   * */
  isValid = errors => (
    Object.keys(errors)
      .reduce((isValid, name) => (
        isValid && errors[name] === null
      ), true)
  );

  handleFormChange = ({ target: { name, value, checked } }) => {
    const { result, errors } = this.state;
    // create new result
    const resultNew = { ...result };
    if (name === 'areas1' || name === 'areas2') {
      const { areas1, areas2 } = this.props;
      const areas = name === 'areas1' ? areas1 : areas2;
      const area = areas[value];
      const selection = new Set(result[name]);
      if (checked) {
        selection.add(area);
      } else {
        selection.delete(area);
      }
      resultNew[name] = selection;
    } else {
      resultNew[name] = value;
    }
    // check errors + set state
    if (!errors[name]) {
      // update only result if there was no error before
      this.setState({
        result: resultNew,
      });
    } else {
      // update result and errors if there was an error before
      const errorsNew = { ...errors };
      errorsNew[name] = this.validate(name, resultNew[name]);
      // update state
      this.setState({
        result: resultNew,
        errors: errorsNew,
      });
    }
  };

  handleFormSubmit = (event) => {
    // prevent page reload
    event.preventDefault();
    const { result } = this.state;
    // validate all fields
    const errors = this.validateAll(result);
    const isValid = this.isValid(errors);
    if (!isValid) {
      // Update error object in case of a validation error
      this.setState({
        isSubmitted: true,
        errors,
      });
    } else {
      // form is valid -> call finish handler
      const { onFinished } = this.props;
      // transform area sets to single array
      const { areas1, areas2, ...rest } = result;
      const areaArray = [...areas1, ...areas2];
      const finalResult = { areas: areaArray, ...rest };
      onFinished(finalResult);
    }
  };

  getResultsForPage = (result, page) => {
    // Given a page number, return relevant items in the result object
    switch (page) {
      case 1:
        return (({ email, location }) => ({ email, location }))(result);
      case 2:
        return (({ areas1 }) => ({ areas1 }))(result);

      case 3:
        return (({ areas2 }) => ({ areas2 }))(result);

      default:
        return {};
    }
  }

  handleNextPage = (event) => {
    event.preventDefault();
    const { page, result } = this.state;
    const fields = this.getResultsForPage(result, page);
    const errors = this.validateAll(fields);
    const isValid = this.isValid(errors);
    if (isValid) {
      this.setState({
        page: page + 1,
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      this.setState({
        errors,
      });
    }
  }

  isPageValid = (page) => {
    const { result } = this.state;
    const fields = this.getResultsForPage(result, page);
    const errors = this.validateAll(fields);
    return this.isValid(errors);
  }

  render() {
    const { areas1, areas2, children } = this.props;
    const {
      result, errors, isSubmitted, page,
    } = this.state;
    const isFormValid = this.isValid(errors);
    const isPageValid = this.isPageValid(page);
    return (
      <Container maxWidth="small">
        <Box pad="medium" gap="medium">
          <SurveyForm
            areas1={areas1}
            areas2={areas2}
            result={result}
            handleChange={this.handleFormChange}
            errors={errors}
            page={page}
          />
          {children}
        </Box>
        <Controls maxWidth="small">
          { page === 3 ? (
            <SubmitButton
              label={(isSubmitted && !isFormValid) ? 'Please check your input' : 'Next'}
              primary
              onClick={this.handleFormSubmit}
              disabled={!isPageValid}
            />
          ) : (
            <SubmitButton
              label="Next"
              primary
              onClick={this.handleNextPage}
              disabled={!isPageValid}
            />
          )}
        </Controls>
      </Container>
    );
  }
}

StepSurvey.propTypes = {
  areas1: areaArrayType.isRequired,
  areas2: areaArrayType.isRequired,
  onFinished: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default StepSurvey;
