import React, { Component } from 'react';
import { Grommet, Text } from 'grommet';
import PropType from 'prop-types';

import Firebase, { withFirebase } from '../Firebase';
import ReactGA from 'react-ga';
import Result from '../Result';
import {
  areaDataCommunity, areaDataPlace, imageData, projectData,
} from '../../data';
import {
  getResultIdFromUrl, getUrlFromResultId, isListUrl, isCsvUrl,
} from '../../helpers/url';
import * as screens from './screens';
import Wrapper from './Wrapper';
import MainContent from './MainContent';
import Header from '../Header';
import Footer from '../Footer';
import theme from '../../theme';
import StepSurvey from '../StepSurvey';
import StepSwiper from '../StepSwiper';
import StepPicker from '../StepPicker';
import LoadingScreen from '../LoadingScreen';
import { timer } from '../../helpers/async';
import { buildResult, getInitialImages } from './logic';
import ErrorScreen from '../ErrorScreen';
import ErrorNotFound from './ErrorNotFound';
import ErrorDefault from './ErrorDefault';
import { shuffle } from '../../helpers/array';
import downloadCsv from '../../helpers/csv';
import ResultList from '../ResultList';
import { CsvResults } from '../CsvResults';
import { errorCodes } from '../../helpers/error';

class App extends Component {
  initialState = {
    screen: null,
    // step state
    swiper: {},
    survey: {},
    picker: {},
    // result
    result: null,
    results: null,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  // --- initialize app --- //

  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    this.initApp();
    window.onpopstate = this.reset;
  }

  // --- helpers --- //

  buildResultFromState = () => {
    const {
      survey: { location, email, areas },
      picker: { images },
    } = this.state;
    return buildResult(projectData, images, areas, location, email);
  };


  // --- state transition --- //

  initApp = () => {
    const currentUrl = window.location.href;
    const showListUrl = isListUrl(currentUrl);
    const resultId = getResultIdFromUrl(currentUrl);
    const csvUrl = isCsvUrl(currentUrl);
    ReactGA.pageview(window.location.pathname);
    if (showListUrl) {
      // show all results
      this.setState({
        screen: screens.LOADING_RESULT,
      }, () => {
        this.loadAndShowAllResults();
      });
    } else if (csvUrl) {
      // Load result filter/download UI
      this.setState({
        screen: screens.CSV_RESULTS,
      });
    } else if (resultId) {
      // result id passed -> load result
      this.setState({
        screen: screens.LOADING_RESULT,
      }, () => {
        this.loadAndShowResult(resultId);
      });
    } else {
      // No result present -> go to first step
      this.gotToFirstStep();
    }
  };

  reset = () => {
    this.setState(this.initialState);
    this.initApp();
  };

  gotToFirstStep = () => {
    this.setState({
      screen: screens.SURVEY,
      survey: {
        areas1: shuffle(areaDataPlace),
        areas2: shuffle(areaDataCommunity),
      },
    });
  };

  saveAndShowResult = () => {
    // build result obj
    const unsavedResult = this.buildResultFromState();
    if (unsavedResult === null) {
      this.setState({
        screen: screens.ERR_INTERNAL,
      });
      return;
    }
    // save result
    const { firebase } = this.props;
    const saveResultPromise = firebase.saveResult(unsavedResult);
    // fake a minimum processing time
    const timerPromise = timer(3000);
    Promise.all([saveResultPromise, timerPromise])
      .then(([result]) => {
        const url = getUrlFromResultId(result.id);
        window.history.pushState(null, null, url);
        this.setState({
          screen: screens.RESULT,
          result,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        this.setState({
          screen: screens.ERR_INTERNAL,
        });
      });
  };

  loadAndShowResult = (resultId) => {
    const { firebase } = this.props;
    firebase.fetchResult(resultId)
      .then((result) => {
        this.setState({
          screen: screens.RESULT,
          result,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.name === errorCodes.NOT_FOUND) {
          this.setState({
            screen: screens.ERR_NOT_FOUND,
          });
        } else {
          this.setState({
            screen: screens.ERR_INTERNAL,
          });
        }
      });
  };

  loadAndShowAllResults = () => {
    const { firebase } = this.props;
    firebase.fetchAllResults()
      .then((results) => {
        this.setState({
          screen: screens.RESULT_LIST,
          results,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.setState({
          screen: screens.ERR_INTERNAL,
        });
      });
  };

  loadFilteredResults = (fromDate, toDate) => {
    const { firebase } = this.props;
    firebase.fetchFilteredResults(fromDate, toDate)
      .then((results) => {
        downloadCsv(results, fromDate, toDate);
        this.setState({
          results,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.setState({
          screen: screens.ERR_INTERNAL,
        });
      });
  };


  // --- step callbacks --- //


  handleSurveyFinished = (surveyRes) => {
    const { areas } = surveyRes;
    // get initial images depending on selected areas
    const images = getInitialImages(imageData, areas, 30);
    // store survey and continue with next step
    this.setState({
      survey: surveyRes,
      screen: screens.SWIPER,
      swiper: {
        images,
        round: 1,
      },
    });
    window.scroll(0, 0);
  };

  handleSwiperFinished = (selectedImages) => {
    const imagesCount = selectedImages.length;
    const { swiper: { round, images: prevImages } } = this.state;
    if (imagesCount === 0) {
      // no image was selected
      // -> next round
      this.setState({
        swiper: {
          round: round + 1,
          images: prevImages,
        },
      });
    } else if (imagesCount <= 5) {
      // less than 5 were selected
      // -> store selection and show result
      this.setState({
        screen: screens.SAVING_RESULT,
        picker: {
          images: selectedImages,
        },
      }, () => {
        this.saveAndShowResult();
      });
    } else {
      // more than 5 were selected
      // -> store selection and pass to picker
      this.setState({
        screen: screens.PICKER,
        picker: {
          images: selectedImages,
        },
      });
    }
    window.scroll(0, 0);
  };

  handlePickerFinished = (images) => {
    this.setState({
      screen: screens.SAVING_RESULT,
      picker: {
        images,
      },
    }, () => {
      this.saveAndShowResult();
    });
    window.scroll(0, 0);
  };

  // --- render --- //

  renderScreen() {
    const {
      screen, survey, swiper, picker, result, results,
    } = this.state;

    switch (screen) {
      case screens.LOADING_RESULT:
        return (
          <LoadingScreen />
        );
      case screens.ERR_NOT_FOUND:
        return (
          <ErrorScreen>
            <ErrorNotFound />
          </ErrorScreen>
        );
      case screens.SURVEY:
        return (
          <StepSurvey
            areas1={survey.areas1}
            areas2={survey.areas2}
            onFinished={this.handleSurveyFinished}
          >
            <Footer />
          </StepSurvey>
        );
      case screens.SWIPER:
        return (
          <StepSwiper
            key={swiper.round}
            images={swiper.images}
            onFinished={this.handleSwiperFinished}
          />
        );
      case screens.PICKER:
        return (
          <StepPicker
            images={picker.images}
            onFinished={this.handlePickerFinished}
          />
        );
      case screens.SAVING_RESULT:
        return (
          <LoadingScreen>
            <Text textAlign="center" pad="large" size="large">
              Just relax while we are finding suitable projects for you...
            </Text>
          </LoadingScreen>
        );
      case screens.RESULT:
        return (
          <Result
            result={result}
          >
            <Footer />
          </Result>
        );
      case screens.RESULT_LIST:
        return <ResultList results={results} />;
      case screens.CSV_RESULTS:
        return (
          <CsvResults
            getResults={this.loadFilteredResults}
            results={results}
          />
        );
      case screens.ERR_INTERNAL:
        return (
          <ErrorScreen>
            <ErrorDefault />
          </ErrorScreen>
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <Grommet theme={theme}>
        <Wrapper>
          <Header />
          <MainContent>
            {this.renderScreen()}
          </MainContent>
        </Wrapper>
      </Grommet>
    );
  }
}

App.propTypes = {
  firebase: PropType.instanceOf(Firebase).isRequired,
};

export default withFirebase(App);
