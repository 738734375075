import React from 'react';
import { Box, Text } from 'grommet';
import { PrimaryHeading } from '../Shared';
import YouTube from '../YouTube';


const Intro = () => (
  <Box pad="small" gap="large" responsive={false}>
    <PrimaryHeading>
      Welcome to CoDesign Studio’s ideation tool!
    </PrimaryHeading>
    <Text>
      Want to improve your local neighbourhood but don't know where to start?
      This fun tool will recommend project ideas that best meet the needs of your
      neighbourhood
      and your community.
    </Text>
    <YouTube id="m7uK2BGusBU" maxWidth="500px" />
    <Text>
      We’re going to ask a few questions, then show you a
      series of
      images, to prompt, inspire, and connect you to your next bright idea.
    </Text>
  </Box>
);

export default Intro;
