import React from 'react';
import { Box, Text } from 'grommet';
import { PrimaryHeading } from '../Shared';

const IntroText = () => (
  <Box gap="medium" pad="small">
    <PrimaryHeading level={2} margin="none">
      Results!
    </PrimaryHeading>
    <Text>
      Congratulations! Based on your input we’ve calculated the following placemaking ideas for you
      and your community.
    </Text>
  </Box>
);

export default IntroText;
