import React from 'react';
import { Box, Text } from 'grommet';
import { PrimaryHeading } from '../Shared';

const IntroText = () => (
  <Box gap="medium" pad="small">
    <PrimaryHeading>
      Well done!
      <br />
      Now to refine your selections.
    </PrimaryHeading>
    <Text>
      Can you pick your top 5?
      <br />
      Your results will be revealed on the next page!
    </Text>
  </Box>
);

export default IntroText;
