import React from 'react';
import { Button, Text } from 'grommet';


const ErrorNotFound = () => (
  <React.Fragment>
    <Text>
      That wasn&apos;t supposed to happen.
      <br />
      Would you like to try it again?
    </Text>
    <br />
    <Button
      href="/"
      label="Start over"
      alignSelf="start"
      primary
    />
  </React.Fragment>
);


export default ErrorNotFound;
