import React from 'react';
import { Box, Text } from 'grommet';
import { PrimaryHeading } from '../Shared';

const IntroText = () => (
  <Box
    gap="medium"
    pad={{
      left: 'medium',
      right: 'medium',
    }}
  >
    <PrimaryHeading>
      Which images appeal to you?
    </PrimaryHeading>
    <Text>
      Click thumbs up or thumbs down.
      <br />
      No need to overthink it, have fun!
    </Text>
  </Box>
);

export default IntroText;
