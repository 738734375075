/**
 * Shorthand func to set the css properties
 * 'top', 'right', 'bottom' and 'left'.
 * Behaves the same way as most css shorthands:
 * - (x) -> applies x to all four properties
 * - (y, y) -> applies x to top/bottom, y to left/right
 * - (x, y, z, q) -> applies properties clockwise
 * */
// eslint-disable-next-line import/prefer-default-export
export const position = (top, right = null, bottom = null, left = null) => `
  top: ${top};
  left: ${left || top};
  right: ${right || left || top};
  bottom: ${bottom || top};
`;
