import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

import { Container } from '../Layout';
import Emoji from '../Emoji';


const ErrorScreen = ({ children }) => (
  <Container maxWidth="xsmall">
    <Box pad="large" gap="medium">
      <Heading level={2} margin="none">
        Oh no!
        &nbsp;
        <Emoji symbol="🥺" />
      </Heading>
      {children}
    </Box>
  </Container>
);


ErrorScreen.defaultProps = {
  children: null,
};


ErrorScreen.propTypes = {
  children: PropTypes.node,
};


export default ErrorScreen;
