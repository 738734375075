import React from 'react';
import { Box, Heading } from 'grommet';
import styled from 'styled-components';


const StyledImage = styled.img`
  height: 60px;
  width: auto;
`;


const Header = () => (
  <header>
    <Box
      pad="medium"
      direction="row"
      justify="center"
      align="center"
    >
      <Heading
        level={1}
        style={{
          // override theme settings because of a grommet bug
          // https://github.com/grommet/grommet/issues/3161
          fontSize: '18px',
        }}
        margin={{ right: 'xsmall' }}
      >
        Ideate
      </Heading>
      <Heading
        level={1}
        style={{
          fontSize: '18px',
        }}
        margin={{ right: 'small' }}
        as="span"
      >
        by
      </Heading>
      <StyledImage src="/img/codesign_logo_black.png" alt="Logo of 'CoDesign Studios'" />
      <StyledImage src="/img/np_logo.png" alt="Logo of 'The Neighbourhood Project'" />
    </Box>
  </header>
);

export default Header;
