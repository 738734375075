import React from 'react';
import { Box, Text, Anchor } from 'grommet';

const Footer = () => (
  <Box
    pad="small"
    size="small"
    direction="row"
  >
    <Text
      size="small"
      color="dark-5"
      textAlign="start"
    >
      Use of this site, including the collection and use of personal information is subject to
      our
      <br />
      <Anchor
        color="dark-3"
        target="_blank"
        href={
          `${process.env.PUBLIC_URL}/pdf/terms_of_use20190826.pdf`
        }
      >
        Website Terms of Use
      </Anchor>
      &nbsp;and&nbsp;
      <Anchor
        color="dark-3"
        target="_blank"
        href={
          `${process.env.PUBLIC_URL}/pdf/privacy_policy20190826.pdf`
        }
      >
        Privacy Policy
      </Anchor>
      .
    </Text>
  </Box>
);

export default Footer;
