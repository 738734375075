import React from 'react';
import { Box, Text } from 'grommet';
import {
  EmailIcon, EmailShareButton,
  FacebookIcon, FacebookShareButton,
  LinkedinIcon, LinkedinShareButton,
  TwitterShareButton, TwitterIcon,
} from 'react-share';
import PropTypes from 'prop-types';
import { SecondaryHeading } from '../Shared';

const Share = ({ url, shareUrl, shortenedUrl }) => {
  const shareText = 'Look at the placemaking ideas I matched with! What did you get?';
  return (
    <Box gap="medium">
      <Box>
        <SecondaryHeading>
          Share your results
        </SecondaryHeading>
        <Text
          size="xsmall"
          textAlign="center"
          color="dark-4"
        >
          Choose one of the icons below to share your results with your community.
        </Text>
      </Box>
      <Box
        direction="row"
        pad="small"
        gap="medium"
        alignContent="center"
        justify="center"
      >
        <FacebookShareButton
          url={shortenedUrl}
          style={{ cursor: 'pointer' }}
        >
          <FacebookIcon size={48} round />
        </FacebookShareButton>
        <TwitterShareButton
          title={shareText}
          url={shortenedUrl}
          style={{ cursor: 'pointer' }}
        >
          <TwitterIcon size={48} round />
        </TwitterShareButton>
        <LinkedinShareButton
          url={shareUrl}
          style={{ cursor: 'pointer' }}
        >
          <LinkedinIcon size={48} round />
        </LinkedinShareButton>
        <EmailShareButton
          url={url}
          subject={shareText}
          openWindow
          style={{ cursor: 'pointer' }}
        >
          <EmailIcon size={48} round />
        </EmailShareButton>
      </Box>
    </Box>
  );
};

Share.propTypes = {
  shortenedUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  shareUrl: PropTypes.string.isRequired,
};

export default Share;
