import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

/**
 * Resolves the given breakpoint alias like 'small' to
 * its corresponding px value.
 * */
const getMaxWidth = ({ maxWidth, theme: { global: { breakpoints } } }) => {
  const maxWidthValue = breakpoints[maxWidth].value;
  return `${maxWidthValue}px`;
};


/**
 * Component that layouts it's children vertically while restricting
 * their width to a given maximum.
 * `maxWidth` value must match one of the existing breakpoint aliases.
 * */
const Container = styled(Box)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: ${getMaxWidth};
  width: 100%;
  background-color: white;
  border-radius: 12px;
`;

Container.propTypes = {
  maxWidth: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']).isRequired,
};

export default Container;
