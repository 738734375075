import { Heading } from 'grommet/es6';
import React from 'react';
import PropTypes from 'prop-types';

const SecondaryHeading = ({ children }) => (
  <Heading
    level={3}
    margin="none"
    style={{
      textTransform: 'uppercase',
      textAlign: 'center',
      margin: '0 auto',
    }}
    color="brand"
  >
    {children}
  </Heading>
);

SecondaryHeading.propTypes = {
  children: PropTypes.node.isRequired,
};


export default SecondaryHeading;
