import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { position } from '../../helpers/style';


const ControlsContainer = styled.div`
  position: fixed;
  left: 12px;
  right: 12px;
  bottom: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
`;

const Inner = styled.div`
  position: relative;
  z-index: 1;
  box-shadow: 0 60px 70px 25px ${({ theme }) => theme.global.colors.brand};
  border-radius: 30px;
  
  padding: 10px;
  max-width: 85%;
  
  display: flex;
  justify-content: stretch;

  &:before, &:after {
    content: '';
    position: absolute;
    ${position(0)};
    z-index: -1;
    background-color: white;
    transform-origin: 0 0;
    border-radius: 30px 30px 0 0;
  }
  
  &:before {    
    transform: skewx(20deg);
  }
  
  &:after {    
    transform: skewx(-20deg);
  }
`;


const Controls = ({ children }) => (
  <ControlsContainer>
    <Inner>
      {children}
    </Inner>
  </ControlsContainer>
);


Controls.propTypes = {
  children: PropTypes.node.isRequired,
};


export default Controls;
