import React from 'react';
import { Box, Text, Heading } from 'grommet';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { projectType } from '../../helpers/propTypes';
import Image from '../Image';


const ProjectsContainer = styled(Box)`


  @media(min-width: ${({ theme }) => theme.global.breakpoints.small.value}px) {
    flex-direction: row;
    > * {
      flex-basis: 0;
      flex-grow: 1;
      &:not(:first-child) {
        margin: 0 0 0 15px;
      }
    }
  }
  @media(max-width: ${({ theme }) => theme.global.breakpoints.small.value - 1}px) {
    flex-direction: column;
    > *:not(:first-child) {
      margin: 15px 0 0 0;
    }
  }
`;

const ProjectContainer = styled(Box)`
  overflow: hidden;
`;

const ImageContainer = styled(Box)`
   height: 150px;
   width: 100%;
   ${({ theme: { global: { colors } } }) => `
     background-color: ${colors['bg-1']};
     background: linear-gradient(45deg, ${colors['bg-1']}, ${colors['bg-2']});
   `};

   img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   }
`;

const Project = ({ project: { name, description, image } }) => (
  <ProjectContainer
    background="light-2"
    round="small"
  >
    <ImageContainer>
      <Image name={image} />
    </ImageContainer>
    <Box pad="medium" gap="small">
      <Heading
        level={4}
        margin="none"
      >
        {name}
      </Heading>
      <Text size="small">
        {description}
      </Text>
    </Box>
  </ProjectContainer>
);

Project.propTypes = {
  project: projectType.isRequired,
};

const Projects = ({ projects }) => (
  <ProjectsContainer>
    {
      projects.map(project => (
        <Project key={project.name} project={project} />
      ))
    }
  </ProjectsContainer>
);

Projects.propTypes = {
  projects: PropTypes.arrayOf(projectType).isRequired,
};


export default Projects;
