import styled from 'styled-components';


/**
 * Child of `Wrapper` component.
 * The MainContent takes all remaining space (vertically).
 * */
const MainContent = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 12px 80px 12px;
  border-radius: 5px;
  z-index: 10;
`;

export default MainContent;
