import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'grommet';

import Spinner from '../Spinner';


const LoadingScreenContainer = styled(Box)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 30vh;
`;


const LoadingScreen = ({ children }) => (
  <LoadingScreenContainer
    pad="large"
    gap="xlarge"
  >
    <Spinner />
    {children}
  </LoadingScreenContainer>
);


LoadingScreen.defaultProps = {
  children: null,
};


LoadingScreen.propTypes = {
  children: PropTypes.node,
};


export default LoadingScreen;
