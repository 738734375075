import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import Swiper, { SwiperButtons } from '../Swiper';
import { imgType } from '../../helpers/propTypes';
import { Container } from '../Layout';
import IntroText from './IntroText';
import Controls from '../Controls';


class StepSwiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: props.images,
      selection: [],
      currentIdx: 0,
    };
  }

  handleClickYes = () => {
    this.handleClick(true);
  };

  handleClickNo = () => {
    this.handleClick(false);
  };

  handleClick(clickedYes) {
    const { images, selection, currentIdx } = this.state;
    const currentImg = images[currentIdx];
    const nextIdx = currentIdx + 1;
    const selectionNew = clickedYes
      ? [...selection, currentImg]
      : selection;
    if (nextIdx < images.length) {
      this.setState({
        selection: selectionNew,
        currentIdx: nextIdx,
      });
    } else {
      const { onFinished } = this.props;
      onFinished(selectionNew);
    }
  }

  render() {
    const { images, currentIdx, selection } = this.state;
    const sliceCount = images.slice(currentIdx, currentIdx + 3);
    const likes = selection.length;
    const progress = currentIdx * 100 / images.length;

    return (
      <Container maxWidth="xsmall">
        <Box
          pad={{
            top: 'large',
            bottom: 'large',
          }}
        >
          <IntroText />
          <Swiper
            images={sliceCount}

            likesCount={likes}
            progressPct={progress}
          />
        </Box>
        <Controls>
          <SwiperButtons
            handleClickNo={this.handleClickNo}
            handleClickYes={this.handleClickYes}
          />
        </Controls>
      </Container>
    );
  }
}

StepSwiper.propTypes = {
  images: PropTypes.arrayOf(imgType).isRequired,
  onFinished: PropTypes.func.isRequired,
};

export default StepSwiper;
