import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet/es6';
import PropTypes from 'prop-types';
import { CheckButton } from '../Form';
import { areaArrayType } from '../../helpers/propTypes';

const StyledAreasContainer = styled(Box)`
  margin-top: -10px;
  margin-left: -10px;
  max-width: none;
  padding-bottom: 10px;
  padding-top: 11px;

  * {
    flex-grow: 1;
    margin-left: 10px;
    margin-top: 10px;
  }
`;


const AreaFields = (
  {
    name, areas, selection, handleChange,
  },
) => (
  <StyledAreasContainer
    flex
    direction="row"
    justify="stretch"
    wrap
  >
    {
      areas.map((area, idx) => (
        <CheckButton
          name={name}
          label={area.name}
          key={area.name}
          value={idx}
          checked={selection.has(area)}
          onChange={handleChange}
        />
      ))
    }
  </StyledAreasContainer>
);

AreaFields.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object.isRequired,
  areas: areaArrayType.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AreaFields;
