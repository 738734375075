import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

const AnimatedBox = ({
  children, type, duration, delay,
}) => (
  <Box
    animation={{
      type,
      duration,
      delay,
    }}
  >
    { children }
  </Box>
);

AnimatedBox.defaultProps = {
  duration: 1000,
  delay: 0,
};

AnimatedBox.propTypes = {
  type: PropTypes.oneOf([
    'fadeIn',
    'fadeOut',
    'jiggle',
    'pulse',
    'slideUp',
    'slideDown',
    'slideLeft',
    'slideRight',
    'zoomIn',
    'zoomOut',
  ]).isRequired,
  duration: PropTypes.number,
  delay: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default AnimatedBox;
