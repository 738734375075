const theme = {
  global: {
    font: {
      family: 'Helvetica, Arial, sans-serif',
      size: '16px',
      height: '20px',
    },
    breakpoints: {
      xsmall: {
        value: 480,
      },
    },
    edgeSize: {
      xlarge: '72px', // default: 96px
    },
    colors: {
      brand: '#047A86',
      'accent-1': '#2EBFB7',
      'accent-1-dark': '#2aa79f',
      'bg-1': '#ffe760',
      'bg-2': '#FDB813',
    },
    focus: {
      border: {
        color: '#F2F2F2',
      },
    },
  },
  heading: {
    level: {
      1: {
        font: {
          weight: 600,
        },
      },
      2: {
        font: {
          weight: 400,
          family: 'Brandon Grotesque',
        },
        medium: {
          size: '40px', // default 50px
          height: '46px', // default 56px
        },
      },
      3: {
        font: {
          weight: 400,
          family: 'Brandon Grotesque',
        },
        medium: {
          size: '20px', // default 50px
          height: '26px', // default 56px
        },
      },
    },
  },
};

export default theme;
