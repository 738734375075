import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet/es6';
import { shuffle } from '../../helpers/array';
import { imgType } from '../../helpers/propTypes';
import { Container } from '../Layout';
import ImagePicker from '../ImagePicker';
import { SubmitButton } from '../Shared';
import ProgressCounter from './PogressCounter';
import IntroText from './IntroText';
import Controls from '../Controls';


class StepPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: shuffle(props.images),
      selection: new Set(),
      selectionCountTarget: Math.min(props.images.length, 5),
    };
  }

  handleSelect = (image) => {
    // toggle selection on click
    const { selection } = this.state;
    if (selection.has(image)) {
      selection.delete(image);
    } else {
      selection.add(image);
    }
    this.setState({ selection });
  };

  handleSubmit = () => {
    const { selection } = this.state;
    const { onFinished } = this.props;
    // create array from set as return value
    const selectionArray = Array.from(selection);
    onFinished(selectionArray);
  };

  render() {
    const { images, selection, selectionCountTarget } = this.state;
    const selectionCount = selection.size;
    const selectionIsValid = selectionCount === selectionCountTarget;
    const limitIsExceeded = selectionCount > selectionCountTarget;

    return (
      <Container maxWidth="small">
        <Box pad="medium" gap="medium">
          <IntroText />
          <Box gap="small">
            <ProgressCounter
              count={selectionCount}
              limit={selectionCountTarget}
              isCompleted={selectionIsValid}
              isExceeded={limitIsExceeded}
            />
            <ImagePicker
              images={images}
              selection={selection}
              onSelect={this.handleSelect}
            />
          </Box>
        </Box>
        <Controls>
          <SubmitButton
            disabled={!selectionIsValid}
            onClick={this.handleSubmit}
            label="next"
            primary
          />
        </Controls>
      </Container>
    );
  }
}

StepPicker.propTypes = {
  images: PropTypes.arrayOf(imgType).isRequired,
  onFinished: PropTypes.func.isRequired,
};

export default StepPicker;
