import React from 'react';
import { Calendar } from 'grommet';
import PropTypes from 'prop-types';

class DateRange extends React.Component {
  onSelectRange = (selectedDate) => {
    const { onSelect } = this.props;

    if (Array.isArray(selectedDate)) {
      onSelect({ dates: selectedDate });
    } else {
      onSelect({ date: selectedDate });
    }
  }

  render() {
    const { dates, date } = this.props;
    return (
      <React.Fragment>
        <Calendar
          range
          date={date}
          dates={dates}
          onSelect={this.onSelectRange}
        />
      </React.Fragment>
    );
  }
}

DateRange.defaultProps = {
  date: null,
  dates: null,
};

DateRange.propTypes = {
  date: PropTypes.string,
  dates: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default DateRange;
