export default [
  {
    name: 'I\'m lonely',
    tags: [
      'meet',
      'socialconnection',
      'gather',
      'belonging',
    ],
  },
  {
    name: 'Youth have nothing to do',
    tags: [
      'youth',
      'activity',
      'events',
      'sports',
    ],
  },
  {
    name: 'I feel culturally isolated',
    tags: [
      'multicultural',
      'belonging',
      'culturalconnection',
      'indigenous',
    ],
  },
  {
    name: 'I want to learn something new',
    tags: [
      'skillsharing',
      'learning',
      'education',
      'storytelling',
    ],
  },
  {
    name: 'I don\'t feel safe',
    tags: [
      'safety',
      'lights',
      'groups',
      'meet',
    ],
  },
  {
    name: 'I can\'t access public space',
    tags: [
      'accessibility',
      'disability',
      'signage',
      'publicspace',
    ],
  },
  {
    name: 'I don\'t feel proud of my neighbourhood',
    tags: [
      'identity',
      'pride',
      'beautification',
      'creative',
    ],
  },
  {
    name: 'I feel like I don\'t belong',
    tags: [
      'groups',
      'network',
      'socialconnection',
      'belonging',
    ],
  },
  {
    name: 'We don\'t have a sharing culture',
    tags: [
      'resourcesharing',
      'volunteer',
      'skillsharing',
      'fundraising',
    ],
  },
  {
    name: 'We don\'t connect across generations',
    tags: [
      'multigenerational',
      'olderpeople',
      'youth',
      'skillsharing',
    ],
  },
  {
    name: 'We could be more physically active',
    tags: [
      'active',
      'health',
      'exercise',
      'wellbeing',
    ],
  },
  {
    name: 'I find it hard to meet new people',
    tags: [
      'meet',
      'gather',
      'events',
      'digital',
    ],
  },
  {
    name: 'We are disconnected from our indigenous heritage',
    tags: [
      'history',
      'heritage',
      'identity',
      'indigenous',
    ],
  },
  {
    name: 'I need to be able to share information',
    tags: [
      'noticeboard',
      'storytelling',
      'digital',
      'signage',
    ],
  },
  {
    name: 'We don\'t spend enough time outdoors',
    tags: [
      'outdoor',
      'nature',
      'wellbeing',
      'kids',
      'park',
    ],
  },
];
