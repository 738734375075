export default [
  {
    name: 'Local sculpture competition',
    tags: [
      'arts',
      'creative',
      'competition',
      'build',
      'making',
      'pottery',
    ],
    description: 'We love the story of a local neighbourhood that held a garden scarecrow competition for halloween and got the local postman to to judge the winner. But you can hold a creative competition any time! Hold a street wide sculpture competition and create some ephemeral fun in the streets.',
    image: '1135794894.jpg',
  },
  {
    name: 'Wine tasting',
    tags: [
      'adult',
      'meet',
      'wine',
      'tasting',
      'drinks',
      'olderpeople',
      'friends',
    ],
    description: 'There are sure to be a bunch of wine fans in your neighbourhood. Connect with neighbours and consider booking a minibus and going wine tasting in a local winery region together. Or hold your own wine tasting event together - who can guess the grape variety, region or year?',
    image: '658606704.jpg',
  },
  {
    name: 'Kayaking',
    tags: [
      'active',
      'health',
      'exercise',
      'adventure',
      'kayaking',
      'groups',
      'nature',
    ],
    description: 'Rivers, lakes, oceans, and canals are all great for getting out onto the water. Form a regular meet up or pool funds together to hire or buy a shareable kayak. You could even follow in the footsteps of groups in Denmark, who let you hire their kayaks for free, as long as you collect rubbish from the waterways while you\'re at it!',
    image: '513230376.jpg',
  },
  {
    name: 'Urban bee farming',
    tags: [
      'beekeeping',
      'activity',
      'gardening',
      'flowers',
      'communitygarden',
      'ecofriendly',
      'nature',
    ],
    description: 'There\'s something special about keeping your own beehives. Perhaps it\'s the honey sourced directly from your own backyard, or maybe the knowledge that your colony is helping pollinate plants in your area. Set up your own colony, join a local beekeeping club and connect with others who a passionate about bees.',
    image: '889695434.jpg',
  },
  {
    name: 'Car boot sale',
    tags: [
      'vintage',
      'localtrade',
      'market',
      'events',
      'cars',
      'resourcesharing',
      'family',
      'ecofriendly',
    ],
    description: 'One persons junk is another persons gold! Do a spring clean and work with your neighbours to hold a street wide garage sale, swap meet or car boot sale. Make it an annual event!',
    image: '1147357700.jpg',
  },
  {
    name: 'Professional graffiti murals',
    tags: [
      'mural',
      'identity',
      'arts',
      'signage',
      'streetart',
      'publicspace',
      'beautification',
      'pavement',
    ],
    description: 'Not only do high quality street art murals deter tagging, they create local landmarks that attract visitation. If you have a range of walls appropriate then consider holding a street art festival over a weekend. Live art is the bomb!',
    image: '1094085124.jpg',
  },
  {
    name: 'Cafe or pub crawl',
    tags: [
      'drinks',
      'coffee',
      'beer',
      'wine',
      'gather',
      'adult',
      'localtrade',
      'baking',
    ],
    description: 'Map a local route between cafes or pubs and announce the starting point, it\'s a great way to meet your neighbours and discover your local area at the same time. Joining a new group can be daunting, and having an event like this to join in on can be an easier way to meet new people. You\'ll also be supporting your local hospitality industry.',
    image: '998968848.jpg',
  },
  {
    name: 'Flash mob',
    tags: [
      'events',
      'theatre',
      'music',
      'dancing',
      'digital',
      'vibrancy',
      'popup',
      'play',
    ],
    description: 'What happened to the flash mob? These glorious gatherings of humans who used the internet to practice dance routines in isolation, only to then meet at a designated place and time and perform in vibrant synchronicity in public. Search YouTube for many viral videos of flash mobs. What makes a successful one? In our opinion, it\'s less about how many YouTube views you get, and more about the looks on the faces of everyone involved. And we do mean everyone. The participants are usually glowing with excitement, and the surprised onlookers are often looking much the same. Spread the joy.',
    image: 'Valli-woman-dancing-xxl.jpg',
  },
  {
    name: 'Chalk board gallery',
    tags: [
      'signage',
      'arts',
      'streetart',
      'play',
      'beautification',
      'drawing',
      'diy',
      'making',
    ],
    description: 'There is an abundance of local creative talent just waiting to be unlocked. Why not place some blank blackboards and some chalk in your street or local park and watch as they are transformed into local art. ',
    image: '175175106.jpg',
  },
  {
    name: 'Street hammock',
    tags: [
      'lingernode',
      'reflection',
      'mainstreet',
      'youth',
      'meditation',
      'books',
      'localtrade',
      'diy',
    ],
    description: 'Hammocks aren\'t only the domain of beaches and balconies, installing a hammock on the main street or in a park can be a great way to encourage passersby to slow down, rest, and spend a little more time in the neighbourhood. Want to level up? Install a Little Library next to it so people can have something to read while they put their feet up.',
    image: 'Valli-colorful-chinese-lanterns-xxl.jpg',
  },
  {
    name: 'Street poetry',
    tags: [
      'streetart',
      'storytelling',
      'writing',
      'culturalconnection',
      'books',
      'bookclub',
      'mainstreet',
      'olderpeople',
    ],
    description: 'Ever written a love letter to the place you live? How about reflecting the deep pains and longings that exist within the boundary lines of your neighbourhood. A poem can be a few words, a sentence, even a few lines of prose. You don\'t have to be ready to stand up at a slam poetry night, but you could share your reflections in written form. In the long-term get permission to print on public walls, in the short-term use chalk paint, or pin-up poems on tree trunks, you could even handwrite your poems on slips of paper and leave them tucked into window sills and under flower pots ready, peeking out ready for someone to find.',
    image: '822238458.jpg',
  },
  {
    name: 'Cultural and religious events',
    tags: [
      'events',
      'festival',
      'multicultural',
      'culturalconnection',
      'multigenerational',
      'identity',
      'making',
      'warm',
    ],
    description: 'The calendar year is filled with opportunities for getting together with neighbours from a small-scale gathering to a wide-scale event. Having a focus on a particular event often makes these an easier way to meet new people, and a great excuse to put up colour and decorations. Eid, Christmas, Halloween, Diwali, Hanukkah and more, these events provide an opportunity to connect with neighbours, celebrate culture and learn about history.',
    image: '486639328.jpg',
  },
  {
    name: 'Street pantry (food)',
    tags: [
      'littlelibrary',
      'food',
      'meals',
      'resourcesharing',
      'signage',
      'noticeboard',
      'baking',
      'family',
      'olderpeople',
    ],
    description: 'Borrowed from the Little Street Library concept, Street Pantries are popping up in neighbourhoods across the world. Installing a box to leave food supplies for neighbours is a simple way of resource-sharing which doesn\'t require large-scale coordination. Have extra food, or are able to buy some? Leave it in the box for residents, you\'ll be surprised at how quickly it goes! (Beware of other friendly neighbours like possums, or monkeys, and build appropriate boxes accordingly).',
    image: '615604320.jpg',
  },
  {
    name: 'Sketching meetup',
    tags: [
      'outdoor',
      'painting',
      'drawing',
      'park',
      'nature',
      'publicspace',
      'indoor',
      'meet',
      'making',
    ],
    description: 'Meet up to sketch your neighbourhood, get outside in summer, or meet in winter to draw indoors. It needn\'t be expensive art in galleries either, many cities have businesses with giant artworks, sculptures, mobiles, and installations in their foyers. Why not go in together and sketch these amazing works of art on display?',
    image: '938119414.jpg',
  },
  {
    name: 'Coworking meet up',
    tags: [
      'business',
      'coffee',
      'localtrade',
      'meet',
      'activity',
      'digital',
      'network',
      'adult',
      'friends',
    ],
    description: 'More and more people are working from home, which can be great; but it can also be lonely. This has led to more people creating times and spaces to meet up to work alongside each other. With wifi and a portable device, you can work just about anywhere cafes, libraries, local parks, by the river. Have kids? You might even consider taking turns watching each other\'s children while the other parents work.',
    image: '948628914.jpg',
  },
  {
    name: 'DIY bike rake space',
    tags: [
      'bicycle',
      'localtrade',
      'build',
      'mainstreet',
      'publictransport',
      'active',
      'diy',
      'dogs',
      'environment',
    ],
    description: 'Local traders often have an opportunity right on their doorstep. By building their own bicycle racks out front of their stores, you are making it easier for locals to access your street while also encouraging them to pause right on your doorstep.. Then they notice your display, or smell or coffee... and the rest is history!',
    image: '509722755.jpg',
  },
  {
    name: 'Neighbourhood bake-off',
    tags: [
      'baking',
      'fundraising',
      'competition',
      'food',
      'cooking',
      'multicultural',
      'home',
      'socialconnection',
      'tea',
    ],
    description: 'Do you enjoy cooking and a bit of light hearted competition? A neighbourhood bake-off is the perfect activity to meet new people in your community and the best part is it can be hosted in the comfort of your home. ',
    image: '997359378.jpg',
  },
  {
    name: 'Knit bombing',
    tags: [
      'publicspace',
      'knitting',
      'vibrancy',
      'arts',
      'beautification',
      'colourful',
      'diy',
      'making',
      'trees',
    ],
    description: 'Love to share you knitting skills? Or interested in learning how to knit? Form a local knit group and connect with local people while you do it. Join in with some tactical urbanism fun by wrapping cycle racks, sign post, tree trunks and other streetscape infrastructure in your knitted creations.',
    image: '1069650938.jpg',
  },
  {
    name: 'Adopt a bus stop',
    tags: [
      'build',
      'safety',
      'beautification',
      'gardening',
      'popup',
      'creative',
      'ecofriendly',
      'publictransport',
      'bus',
    ],
    description: 'Let\'s be honest, when its raining out and your waiting for the bus, sometimes being under the shelter is not the greatest place to be. But this doesn\'t have to be the case. Adopt a bus stop and transform it into what ever you like. You could put some art, a couch, a flower pot. Would be worth checking in with council before you act on this one. ',
    image: '489119482.jpg',
  },
  {
    name: 'Document your area (social media, podcast, photography, short stories)',
    tags: [
      'storytelling',
      'digital',
      'film',
      'writing',
      'photography',
      'drawing',
      'reading',
      'youth',
      'instagram',
    ],
    description: 'Local residents always know the best kept secrets in a neighbourhood. This could be as simple as who you think has the best coffee in town or the best local dry cleaner. So why not share your local knowledge with other residents or those visiting the area through social media, podcasts or photography. ',
    image: '1019786062.jpg',
  },
  {
    name: 'Food truck park',
    tags: [
      'foodtruck',
      'streetparty',
      'publicspace',
      'vibrancy',
      'localtrade',
      'park',
      'colourful',
      'popup',
      'festival',
    ],
    description: 'Is there spare open space in your community that could do with a bit of activity? A temporary food truck park is a perfect way to fill the space creating a destination for community to gather in. ',
    image: '1006503210.jpg',
  },
  {
    name: 'Community bonfire',
    tags: [
      'winter',
      'outdoor',
      'multigenerational',
      'multicultural',
      'events',
      'gather',
      'family',
      'night',
      'adventure',
      'socialconnection',
    ],
    description: 'Mid-winter or in the dark of night, a bonfire brings social connection along with it\'s literal warmth. Check for regional permissions regarding fire restrictions, pop a note in your neighbours letterbox or on the noticeboard, and gather together around the glow. ',
    image: '892366948.jpg',
  },
  {
    name: 'Old car and bike festival',
    tags: [
      'gather',
      'meet',
      'olderpeople',
      'multigenerational',
      'cars',
      'history',
      'festival',
      'autumn',
      'mechanic',
      'tools',
    ],
    description: 'People love old cars, old motorbikes, and old trucks! Invite local car clubs to hold club meets and cruises to your public park or main street. Visually vibrant, historically interesting, and a great way to use public space to bring a boost to the neighbourhood. Great for connecting across generations too.',
    image: '868925192.jpg',
  },
  {
    name: 'Ceramics and pottery class',
    tags: [
      'pottery',
      'learning',
      'arts',
      'creative',
      'skillsharing',
      'painting',
      'drawing',
      'groups',
      'indoor',
      'making',
    ],
    description: 'Are you or someone you know a budding artist? Or are you just keen to get your hands on some clay? Ceramics and pottery classes are a great way to flex your creative muscle whilst getting to know members of your local community. ',
    image: '1135794894.jpg',
  },
  {
    name: 'Decorate your front yard',
    tags: [
      'competition',
      'pride',
      'socialconnection',
      'publicspace',
      'yards',
      'diy',
      'build',
      'childfriendly',
      'mechanic',
      'trees',
    ],
    description: 'There are plenty of days in the year to collaborate with your neighbours in fun competition and transform your front yard into a Christmas wonderlands or a spooky Halloween castle or why not have a scarecrow making competition? ',
    image: 'Valli-lavender-flowers-xl.jpg',
  },
  {
    name: 'Beer brewing club',
    tags: [
      'adult',
      'meet',
      'beer',
      'drinks',
      'learning',
      'groups',
      'skillsharing',
      'tasting',
      'belonging',
      'cold',
    ],
    description: 'Craft your own beer and make new friends! Split the cost of a starter kit with your neighbour, and then kick back and enjoy the rewards down the track. Between brewing days and bottling days, there are plenty of chances to get together and connect while crafting something great.',
    image: '1070729494.jpg',
  },
  {
    name: 'Toy library',
    tags: [
      'resourcesharing',
      'parents',
      'kids',
      'indoor',
      'family',
      'volunteer',
      'home',
      'ecofriendly',
      'childfriendly',
      'littlelibrary',
    ],
    description: 'Toy Libraries are not a new idea, but still vitally important for sharing resources for children. The key is finding a space to store the toys, and setting up guidelines for borrowing (there are some great organisations that can help with this!) Toy libraries can serves multiple functions, including: reducing consumption and waste, supplying toys to families that otherwise couldn\'t afford them, promoting children\'s learning with easy transition to older toys as they grow, and helping to minimise clutter.',
    image: '615604320.jpg',
  },
  {
    name: 'Street book library',
    tags: [
      'littlelibrary',
      'books',
      'reading',
      'resourcesharing',
      'signage',
      'noticeboard',
      'family',
      'olderpeople',
      'storytelling',
      'writing',
    ],
    description: 'Little Street Libraries are popping up in neighbourhoods across the world. There is often an honesty system of "take a book, leave a book", or simply return the book to the library when you are done! From reference books and novels, to autobiographies and plays, a little library can open up a world of literature and knowledge to your local community. And don\'t forget the kids! Donating kids books to a little library can encourage outdoor reading, or help out a family in need.',
    image: '605776386.jpg',
  },
  {
    name: 'Dog meet ups',
    tags: [
      'meet',
      'dogs',
      'outdoor',
      'park',
      'socialconnection',
      'family',
      'olderpeople',
      'friends',
      'health',
      'nature',
    ],
    description: 'Dogs love socialising - and so do their owners! It\'s easy to arrange morning and afternoon daily meet ups at parks and reserves. Take it a step further by arranging special dog walk events, group picnics and annual fashion shows!',
    image: '942616500.jpg',
  },
  {
    name: 'Time bank',
    tags: [
      'resourcesharing',
      'volunteer',
      'localtrade',
      'skillsharing',
      'olderpeople',
      'youth',
      'fundraising',
      'preserves',
      'sparetime',
      'tea',
    ],
    description: 'This one requires a little coordinating, but can unlock the power of donating our time to our community. Need someone to quickly hang up that picture, and can offer some gardening in return? What if they don\'t need gardening, but someone else in the neighbourhood does? A Time Bank is a way of registering the hours you \'donate\' with your skill, and then receiving credit to \'draw\' on hours in another skill that you actually need. By pooling together, we can help people get the best help they need at the right time.',
    image: '938464238.jpg',
  },
  {
    name: 'Clothes swap',
    tags: [
      'popup',
      'fashion',
      'localtrade',
      'environment',
      'resourcesharing',
      'vintage',
      'youth',
      'fundraising',
      'market',
      'ecofriendly',
    ],
    description: 'Clothing waste is one of the largest contributors to landfill each year. An easy way to vamp up your wardrobe that won\'t hurt your back pocket or the planet is a clothing swap. One persons trash is another\'s treasure. ',
    image: '955641488.jpg',
  },
  {
    name: 'Community dinners',
    tags: [
      'meals',
      'food',
      'gather',
      'street',
      'socialconnection',
      'fundraising',
      'night',
      'cooking',
      'belonging',
      'winter',
    ],
    description: 'Tables down the footpath of the mainstreet, an open gathering in your home, or a community BBQ in the park; there are dozens of different ways to gather people around food. Perhaps everyone brings a dish, perhaps you connect with local traders, perhaps it\'s a fundraising for a local project, or perhaps it is simply a chance to stop, pause, and gather. We love nothing more than getting together to spend time lingering over a meal with neighbours and friends, new and old. Our hot tip? Different members of your community will be able to attend at different times of day, so perhaps change the start time every now and then and see if different people can make it.',
    image: '1036815628.jpg',
  },
  {
    name: 'Eyebombing',
    tags: [
      'arts',
      'creative',
      'safety',
      'play',
      'pavement',
      'streetart',
      'vibrancy',
      'youth',
      'diy',
      'colourful',
    ],
    description: 'Search the internet for "Eyebombing" and get ready to see how the simple act of adding "googly eyes" to your physical infrastructure in public spaces can give them personality, make them come alive. It\'s a classic, it\'s easy and it\'s fun! Be sure to consider which products you use so that we don\'t add plastics to the environment.',
    image: '177520548.jpg',
  },
  {
    name: 'Trash can painting',
    tags: [
      'arts',
      'painting',
      'competition',
      'streetart',
      'multigenerational',
      'multicultural',
      'beautification',
      'childfriendly',
      'diy',
      'colourful',
    ],
    description: 'Brighten up the streetscape by painting your trash can, and challenge your neighbours to do the same! We are all unique, but there are certain things that unite us - like having identical looking trashcans! Why not create some vibrancy by seeing what wonderful ideas each household can come up with using the same basic trash can or wheelie bin. It might just brighten up your garbo\'s day as well!',
    image: '494182640.jpg',
  },
  {
    name: 'Shop local program',
    tags: [
      'localtrade',
      'competition',
      'business',
      'network',
      'market',
      'mainstreet',
      'preserves',
      'fashion',
      'shopping',
      'ecofriendly',
    ],
    description: 'It is tough times for local traders with online shopping growing in popularity. We love seeing local people band together to support local traders. We also love to see traders offering loyalty discounts to local people who shop regularly. Start up a mainstreet business network, facebook group and hashtag. Because together we are stronger!',
    image: '639534022.jpg',
  },
  {
    name: 'Waterfight / Neighbourhood Nerf gun battle',
    tags: [
      'play',
      'streetparty',
      'family',
      'youth',
      'competition',
      'kids',
      'outdoor',
      'active',
      'games',
      'parents',
    ],
    description: 'We\'ll admit that we have been known to get more than a little bit competitive in a spontaneous laneway waterfight in our neighbourhood. In our defence, it is ridiculously fun. Best saved up for a warm summer\'s day, unless you live in a bright sunny state, in which case we want to evidence of your waterfights on instagram please.',
    image: '932186624.jpg',
  },
  {
    name: 'Fitness trail',
    tags: [
      'exercise',
      'park',
      'active',
      'build',
      'signage',
      'publicspace',
      'adult',
      'diy',
      'health',
      'warm',
    ],
    description: 'Create exercise stations in the park through stencils or stickers that can be applied to the pavement or walls. Encourage fellow community members to join you in doing regular burpees, push ups, squats, lunge walks, planks and other exercises.',
    image: '591834548.jpg',
  },
  {
    name: 'Exercise Club',
    tags: [
      'meet',
      'exercise',
      'active',
      'socialconnection',
      'outdoor',
      'noticeboard',
      'youth',
      'belonging',
      'bicycle',
      'summer',
      'warm',
    ],
    description: 'Sometimes it\'s hard to get the motivation to get moving. But getting up and moving is a lot easier in a group. So why not get some people together for a run/exercise club.',
    image: '610144422.jpg',
  },
  {
    name: 'Bushwalking group',
    tags: [
      'outdoor',
      'socialconnection',
      'nature',
      'walking',
      'health',
      'olderpeople',
      'bushwalk',
      'groups',
      'active',
      'adventure',
      'belonging',
    ],
    description: 'There really is nothing like exploring the great outdoors. Groups that go bushwalking together might find themselves discovering new trails, making new or deeper friendships, and all the while getting a boost to their health and wellbeing. You might start meeting regularly for short walks, and then plan ahead for annual or quarterly adventures further afield.',
    image: '935648828.jpg',
  },
  {
    name: 'Birdwatching club',
    tags: [
      'outdoor',
      'socialconnection',
      'nature',
      'walking',
      'health',
      'olderpeople',
      'birdwatching',
      'groups',
      'active',
      'family',
      'belonging',
    ],
    description: 'Quiet time in nature, enjoying a hobby with others, for a purpose: this is the beauty of birdwatching groups. Birdwatching groups can be a great cross-generational group too. See the world with the new wonder of children\'s eyes, or learn from a local who knows every local bird by name.\n',
    image: '986729140.jpg',
  },
  {
    name: 'Block party',
    tags: [
      'festival',
      'events',
      'streetparty',
      'culturalconnection',
      'pride',
      'music',
      'vibrancy',
      'dancing',
      'childfriendly',
      'colourful',
      'friends',
    ],
    description: 'Its time to reclaim the streets! Work with local authorities to close your street and reclaim the space to for a community celebration. Hold a seasonal celebration. Maximise the fun by planning it with your neighbours.',
    image: '901570694.jpg',
  },
  {
    name: 'Language class',
    tags: [
      'skillsharing',
      'learning',
      'languages',
      'culturalconnection',
      'multicultural',
      'groups',
      'socialconnection',
      'family',
      'olderpeople',
      'reading',
      'education',
    ],
    description: 'Are you fluent in another language? Then you have skills that your local community values! You might like to run, or attend, a language class or conversation catch-up in a local cafe, pub or community space.',
    image: '478438526.jpg',
  },
  {
    name: 'Paint bank (supply for urban painting)',
    tags: [
      'streetart',
      'drawing',
      'painting',
      'resourcesharing',
      'beautification',
      'publicspace',
      'volunteer',
      'signage',
      'youth',
      'making',
      'colourful',
    ],
    description: 'Love supporting local community art murals on streetscape infrastructure? Consider setting up a paint bank in your garage and providing paint, brushes and information brochures for budding artists on how they can obtain approval prior to creating their mural masterpiece.',
    image: '177520548.jpg',
  },
  {
    name: 'Yoga in the park',
    tags: [
      'active',
      'park',
      'health',
      'yoga',
      'meet',
      'activity',
      'reflection',
      'exercise',
      'youth',
      'meditation',
      'summer',
    ],
    description: 'Yoga in the fresh outdoors, is a wonderful way to amplify your practice. Pick a place in the park, set a meeting time, and post a note on local noticeboard or facebook page. Even if you\'re not a world-master-yogi, there are many apps and YouTube videos to guide a 10-, 30-, 60-minute practice. A great way to meet new people by connecting over a shared activity, and a wonderful way to boost wellness out of doors whilst activating our public spaces.',
    image: '1140641838.jpg',
  },
  {
    name: 'Veggie swap',
    tags: [
      'resourcesharing',
      'localtrade',
      'vegetables',
      'food',
      'gardening',
      'environment',
      'green',
      'communitygarden',
      'ecofriendly',
      'wellbeing',
      'market',
    ],
    description: 'Booming tomato plants, but no runner beans? Olives raining from the sky, but no avocados? If you are growing fruits and vegetables in your garden, create a meet up to do a trade, no money required! Something amazing happens when we take a different view of our local areas and see that together we could be growing a local farm, it\'s simply spread across our different property lines and fences.',
    image: '860026356.jpg',
  },
  {
    name: 'Cycle tours',
    tags: [
      'bicycle',
      'nature',
      'tours',
      'activity',
      'volunteer',
      'meet',
      'history',
      'outdoor',
      'daytime',
      'health',
      'travel',
    ],
    description: 'Whether you\'re a keen cyclist or more of a weekend bike rider - a cycle tour is a great way to explore a city and connect with like minded community members. Connect with fellow community members and join a professionally led cycle tour, or form a cycling group and take turns leading tours yourself.',
    image: '1136230389.jpg',
  },
  {
    name: 'Games night',
    tags: [
      'socialconnection',
      'gather',
      'games',
      'adult',
      'groups',
      'indoor',
      'home',
      'night',
      'family',
      'winter',
      'laughing',
    ],
    description: 'Are you looking for a fun weekend activity? Why not organise a community games night with you and your neighbours? And best of all, you can do it in the comfort of your home. ',
    image: '1076877778.jpg',
  },
  {
    name: 'Multicultural dinners',
    tags: [
      'multicultural',
      'culturalconnection',
      'meals',
      'food',
      'gather',
      'meet',
      'tasting',
      'cooking',
      'languages',
      'youth',
      'belonging',
    ],
    description: 'Food is a great connector. Why not hold a series of multicultural community dinners that feature the many delicious cuisines of the cultural groups that live within your neighbourhood. ',
    image: '996097046.jpg',
  },
  {
    name: 'Indigenous weaving, painting and crafts',
    tags: [
      'heritage',
      'history',
      'identity',
      'skillsharing',
      'activity',
      'arts',
      'creative',
      'multicultural',
      'culturalconnection',
      'learning',
      'colourful',
    ],
    description: 'Whether it is a pop-up event, an ongoing class, or a stall at a market or festival, there are many ways to combine your neighbours love for creativity with indigenous learning and storytelling to make for a fun and engaging activity. Meeting in public spaces, such as parks and main street markets, invites passersby to stop and learn as well.',
    image: '492960428.jpg',
  },
  {
    name: 'Indigenous appreciation walk',
    tags: [
      'volunteer',
      'heritage',
      'history',
      'storytelling',
      'walking',
      'nature',
      'reflection',
      'bushwalk',
      'exercise',
      'family',
      'multicultural',
    ],
    description: 'Host a walk through your local area highlighting places and stories of indigenous heritage and importance. A one-off event can lead to more permanent trails being installed with signage and markers to prompt passersby to stop, reflect, and connect.',
    image: '1150730526.jpg',
  },
  {
    name: 'Street gallery',
    tags: [
      'streetart',
      'arts',
      'creative',
      'drawing',
      'painting',
      'publicspace',
      'beautification',
      'identity',
      'pride',
      'mainstreet',
      'dogs',
    ],
    description: 'Art galleries are not be the only place where art can be displayed. Trees, external walls, fences and other street infrastructure provide a great place to hang visual art. Install a light box in a lane or hang a load of five dollar op-shop paintings from the trees in your local park.',
    image: '610259354.jpg',
  },
  {
    name: 'Knitting groups',
    tags: [
      'knitting',
      'arts',
      'creative',
      'groups',
      'socialconnection',
      'home',
      'wellbeing',
      'making',
      'belonging',
      'cold',
      'cosy',
    ],
    description: 'Love to share you knitting skills? Or interested in learning how to knit? Form a local knit group and connect with locals while you do it. This age old skill has grown in popularity in the last decade and is just as relevant as it has ever been. Join in with some tactical urbanism fun by knit-bombing street infrastructure and tree trunks.',
    image: '941272888.jpg',
  },
  {
    name: 'Planter beds using recycled wood',
    tags: [
      'build',
      'gardening',
      'pride',
      'mainstreet',
      'beautification',
      'green',
      'communitygarden',
      'wellbeing',
      'ecofriendly',
      'environment',
      'flowers',
    ],
    description: 'A quick way to add a bit of colour to your main street is to add planter beds. You can use old wine barrels, buy purpose built beds or get together with your neighbours and build your own out of recycled timber.',
    image: 'Valli-flowers-in-market-xl.jpg',
  },
  {
    name: 'Neighbourhood school holiday program',
    tags: [
      'kids',
      'family',
      'play',
      'resourcesharing',
      'youth',
      'parents',
      'volunteer',
      'gardening',
      'activity',
      'education',
      'schools',
    ],
    description: 'Are your children complaining about being bored two days into school holidays? Or are you struggling to find someone to take care of your children while you and your partner are at work? Well, chances are there are other families in your community facing the same problem. One solution is coming up with a number of activities families can do together, and take turns in running them throughout the school holidays. The best part is your children have others to play with and you might meet someone new too.',
    image: '493887232.jpg',
  },
  {
    name: 'BookClub',
    tags: [
      'books',
      'bookclub',
      'reading',
      'writing',
      'activity',
      'gather',
      'indoor',
      'home',
      'belonging',
      'conversations',
      'cosy',
    ],
    description: 'BookClubs are good for young or old. Even better, for young AND old. Diving into story, characters, themes, triumphs, and failures, is a great way to spark conversation, even across cultural and generational divides. Meet in a living room, meet in a pub, meet in a cafe, bring the kids, or don\'t. Just make sure you bring a good book to discuss. ',
    image: '499373254.jpg',
  },
  {
    name: 'Street cricket',
    tags: [
      'street',
      'outdoor',
      'youth',
      'kids',
      'active',
      'health',
      'safety',
      'family',
      'games',
      'streetparty',
      'daytime',
      'mud',
    ],
    description: 'You haven\'t lived if you haven\'t played a game of street cricket with your neighbours. Paint a set of stumps on a rubbish bin, knock on your neighbours door and compete over the \'Ashes\'.',
    image: '1070364492.jpg',
  },
  {
    name: 'Running group',
    tags: [
      'groups',
      'localtrade',
      'youth',
      'adult',
      'meet',
      'activity',
      'outdoor',
      'exercise',
      'running',
      'wellbeing',
      'active',
      'adventure',
    ],
    description: 'Set goals to train for that 5k, 10k, half-mara or more! Someone can coach, or use some of the great apps out there to guide your running training plans together. Half the battle is showing up, and knowing someone is waiting for you can be a strong motivator.\n\nPlus it is a great way to get out amongst the streets, make them feel alive, notice things that are happening, or need fixing. \n\nAnd, as always, there\'s safety in numbers. Running with a partner or group can mean you feel safer to run on dark winter mornings, and you\'ll make other runners and early-risers out there feel safer too!',
    image: '591834548.jpg',
  },
  {
    name: 'Outdoor cinema',
    tags: [
      'film',
      'outdoorcinema',
      'outdoor',
      'park',
      'gather',
      'events',
      'activity',
      'fundraising',
      'family',
      'youth',
      'childfriendly',
      'cosy',
    ],
    description: 'Everyone loves an outdoor cinema - the 21st centuries version of the drive-in! The great thing about an outdoor cinema is that it works at a variety of scales: from the comfort of you back yard, to a big inflatable screen held in the local park. BYO deck chair and picnic essential.',
    image: '984761732.jpg',
  },
  {
    name: 'Scarecrow competitions',
    tags: [
      'build',
      'creative',
      'beautification',
      'play',
      'meet',
      'gardening',
      'vibrancy',
      'competition',
      'communitygarden',
      'family',
      'childfriendly',
      'yards',
    ],
    description: 'With little-coordination required, sometimes it only takes one scarecrow to start a trend on a street. Build one with your family, or together with your next-door-neighbour, plant it in your front yard and just watch, they often seem to multiply pretty quickly! Want to make it happen faster? Consider asking the local primary school to put a "scarecrow competition" in their weekly newsletter, or start an instagram account for these new local creatures.',
    image: '950590446.jpg',
  },
  {
    name: 'Fix it day',
    tags: [
      'skillsharing',
      'resourcesharing',
      'bicycle',
      'olderpeople',
      'multigenerational',
      'cars',
      'build',
      'tools',
      'family',
      'diy',
      'mechanic',
      'repairs',
    ],
    description: 'Often we have idle items in the house that simply need a little care, and there are people in the community who have the skills to fix it. How to match up the needs and skills? Set a time and a place, and have a "fix it" day. It could be themed, such as a Bicycle Hack-A-Thon, where bikes of any size and style can be brought to be worked on together. You can even invite a food truck or a local musician to play, and make a day of it.',
    image: '950671616.jpg',
  },
  {
    name: 'Publish a local recipe book',
    tags: [
      'food',
      'meals',
      'culturalconnection',
      'multicultural',
      'skillsharing',
      'baking',
      'home',
      'writing',
      'preserves',
      'cooking',
      'storytelling',
      'instagram',
    ],
    description: 'The "Tastes of Cranbourne" might be different from the "Tastes of Wagga Wagga", but what they will hopefully have in common is that they are storybooks of the flavours of those communities. A great chance to get secret family recipes or try new cuisines, and can be used as a fundraising for local community projects or causes.',
    image: '1005735718.jpg',
  },
  {
    name: 'Adopt a park bench',
    tags: [
      'beautification',
      'accessibility',
      'streetart',
      'lingernode',
      'arts',
      'build',
      'family',
      'olderpeople',
      'diy',
      'dogs',
      'reading',
      'parkbench',
    ],
    description: 'Is there a park bench in your area that could use a little love? Small changes can make a big difference, and some councils even have programs for you to officially \'adopt\' the bench. Plant a tree for shade, add a new lick of paint or a little welcome sign; these are all ways of improving the seating in public areas. Think about accessibility too!\n',
    image: '1035803962.jpg',
  },
  {
    name: 'Night markets',
    tags: [
      'market',
      'night',
      'events',
      'festival',
      'music',
      'food',
      'pride',
      'safety',
      'vibrancy',
      'coffee',
      'mainstreet',
      'lights',
    ],
    description: 'Whether it\'s keeping the main street open later on a chosen winter\'s eve, or lining the local oval with tables and home-made stalls late into the summer night, Night Markets are a great way to create a buzz, and gather the community together. Be willing to start small, and willing to ask for help. Local musicians are often willing to sing a song or two, and artists might paint a beautiful sign. Throw in some twinkle lights and you\'re half way there. There are many great examples of small-scale night markets that went from a one-off event one year, to become a long-term institution in the local communities annual calendar.',
    image: '899764414.jpg',
  },
  {
    name: 'Verge planting',
    tags: [
      'green',
      'beautification',
      'gardening',
      'environment',
      'park',
      'nature',
      'communitygarden',
      'ecofriendly',
      'mainstreet',
      'flowers',
      'trees',
      'yards',
    ],
    description: 'Verge planting begins with seeing opportunity in unlikely places. Where can you see edges of land, patches of soil, and underutilised space, which could be planted and brought to life? Your private property is all yours to plant, and if it is council land then it is best to get permission. Have a look online for inspiration on how small areas of land planted with greenery can bring fresh life to an area. It\'s good for the environment too!\n',
    image: '950590446.jpg',
  },
  {
    name: 'Solar light-up your streets, parks and walkways',
    tags: [
      'lights',
      'street',
      'environment',
      'safety',
      'accessibility',
      'exercise',
      'night',
      'dogs',
      'localtrade',
      'mainstreet',
      'publictransport',
      'bicycle',
    ],
    description: 'Want to test out how different a space would feel with lighting? Solar lights are a fantastic (and sustainable) way to see the impact lighting can have. Light up running pathways, spot-light heritage buildings and public artworks, keep parks accessible later, make rough terrain easier to navigate for young and old, and demonstrate the difference lighting can make.\n\nThe best part is? You don\'t need to tap into the grid or pay for any power!',
    image: 'Valli-colorful-chinese-lanterns-xxl.jpg',
  },
  {
    name: 'Meals roster',
    tags: [
      'digital',
      'noticeboard',
      'resourcesharing',
      'groups',
      'food',
      'meals',
      'volunteer',
      'network',
      'cooking',
      'home',
      'vegetables',
      'sparetime',
    ],
    description: 'Shout-outs for help on behalf of a friend online have become more common place, and we hope to see it continue! In some communities, when someone has a baby (or has an operation, or loses a loved one, or a whole host of life-interrupting events) they don\'t have to cook for two weeks. Someone, friend or stranger, turns up at their door each afternoon with a meal. It\'s magical, and all it takes is a little coordination, which has been made easier with the age of social media apps. There are always privacy concerns around giving out your address, and rightly so, but consider local pick up points as an alternative. And remember, cooking meals is something the youth can help with too.',
    image: '996097046.jpg',
  },
  {
    name: 'Welcome to the neighbourhood group',
    tags: [
      'groups',
      'network',
      'socialconnection',
      'volunteer',
      'home',
      'multicultural',
      'olderpeople',
      'belonging',
      'books',
      'cosy',
      'friends',
      'laughing',
    ],
    description: 'It is hard moving to a new neighbourhood. Imagine if there was a local group ready to designate you a \'buddy\' to welcome you! Whether you\'re put in touch through a community noticeboard, real estate agents who have sold a property, or through your local council, having a network of neighbours designated to \'welcome\' new residents can make for a smooth transition. Where to start? You could host your new neighbour for a meal, go for a walk to introduce them to your local park, or wander through the local shops together.\n',
    image: 'Valli-young-multiethnic-friends-sitting-on-the-floor-chatting-xxl.jpg',
  },
  {
    name: 'Meditation and wellbeing trail',
    tags: [
      'reflection',
      'yoga',
      'park',
      'health',
      'signage',
      'wellbeing',
      'home',
      'meditation',
      'making',
      'active',
      'nature',
      'travel',
    ],
    description: 'Set up meditation stations throughout a local square, public space, park or nature trail. DIY signage and small creative prompts can be simple ways to establish reflection markers along a trail to encourage people to pause and sow into their wellbeing. You could even leave a little chalk board with chalk tied to a string so that people could leave key words and messages from their reflection times along the trail.',
    image: '954596030.jpg',
  },
  {
    name: 'Nature play',
    tags: [
      'nature',
      'kids',
      'play',
      'park',
      'activity',
      'family',
      'games',
      'wellbeing',
      'environment',
      'bushwalk',
      'childfriendly',
      'mud',
    ],
    description: 'Nature play inspires children, and adults, to connect with nature. Arrange a tour of one of your regions nature playground destinations or go on a bushwalk with fellow families and experience nature play for real! ',
    image: '1059735838.jpg',
  },
  {
    name: '"Men\'s" shed',
    tags: [
      'cars',
      'skillsharing',
      'olderpeople',
      'meet',
      'activity',
      'indoor',
      'build',
      'youth',
      'groups',
      'socialconnection',
      'volunteer',
      'beer',
      'tools',
    ],
    description: '"Men\'s" Shed: the term for a group of people regularly meeting up to tinker, build, fix, restore, and have a good chat too, is not exclusive to men. Whether you\'re fixing your neighbours car which you heard about on the community noticeboard, or building a little library for the local park, there are lots of ways groups like this can use their skills while also fostering a sense of connection and mateship.',
    image: '654361262.jpg',
  },
  {
    name: 'Community garden',
    tags: [
      'green',
      'environment',
      'gardening',
      'skillsharing',
      'outdoor',
      'volunteer',
      'communitygarden',
      'olderpeople',
      'ecofriendly',
      'flowers',
      'health',
      'repairs',
      'trees',
    ],
    description: 'Do you or someone you know have a green thumb and a passion for community? Community gardens are a great way to grow local food, connect people with food production and foster community connection. ',
    image: '911274748.jpg',
  },
  {
    name: 'Community market-garden',
    tags: [
      'localtrade',
      'market',
      'business',
      'environment',
      'gardening',
      'health',
      'volunteer',
      'communitygarden',
      'vegetables',
      'preserves',
      'ecofriendly',
      'daytime',
      'flowers',
    ],
    description: 'Is there an abundance of open space in your community that is calling to be transformed into a productive agricultural space? A market garden is a perfect way for community members grow local produce and reap to benefits of fresh fruits and vegetables. ',
    image: '471217010.jpg',
  },
  {
    name: 'Community picnic',
    tags: [
      'outdoor',
      'park',
      'publicspace',
      'food',
      'gather',
      'tasting',
      'family',
      'kids',
      'meet',
      'games',
      'baking',
      'olderpeople',
      'cooking',
    ],
    description: 'What better way to enjoy a nice sunny afternoon than on a picnic rug in your local park? A community picnic network is a great way to gather people together to enjoy their local green spaces and socialise with their neighbours.',
    image: '153876660.jpg',
  },
  {
    name: 'Linger nodes in main streets',
    tags: [
      'lingernode',
      'creative',
      'build',
      'mainstreet',
      'beautification',
      'socialconnection',
      'yoga',
      'signage',
      'noticeboard',
      'safety',
      'reflection',
      'localtrade',
      'shopping',
    ],
    description: 'Slow down foot traffic in your main street by creating pause points, seating areas, rest stops and linger nodes where people can relax and rest for a moment.',
    image: 'Valli-young-multiethnic-friends-sitting-on-the-floor-chatting-xxl.jpg',
  },
  {
    name: 'Pavement murals',
    tags: [
      'pavement',
      'chalk',
      'painting',
      'family',
      'arts',
      'walking',
      'signage',
      'mural',
      'publicspace',
      'beautification',
      'drawing',
      'youth',
      'colourful',
    ],
    description: 'Footways and pavements are amazing canvasses for artwork. Have some fun with pavement chalk art, have some colourful pavement stickers printed up or stencil designs on your local footway to bring your streets to life. Make sure you put up traffic cones so people don\'t fall over you though!',
    image: '963418362.jpg',
  },
  {
    name: 'Community murals',
    tags: [
      'arts',
      'painting',
      'kids',
      'multigenerational',
      'vibrancy',
      'drawing',
      'identity',
      'mural',
      'activity',
      'publicspace',
      'safety',
      'multicultural',
      'youth',
    ],
    description: 'Local street art is a great way to brighten up a neighbourhood. Engage a local artist and the community in the design and implementation process. ',
    image: '494182640.jpg',
  },
  {
    name: 'Tree planting day',
    tags: [
      'green',
      'environment',
      'gardening',
      'volunteer',
      'events',
      'beautification',
      'nature',
      'publicspace',
      'family',
      'communitygarden',
      'ecofriendly',
      'mud',
      'trees',
    ],
    description: 'Revegetation projects are held in urban and regional environments. Get your hands dirty with your family and neighbourhood by volunteering together at a tree planting day. ',
    image: '911274748.jpg',
  },
  {
    name: 'Open-air classroom',
    tags: [
      'learning',
      'activity',
      'skillsharing',
      'publicspace',
      'meet',
      'education',
      'culturalconnection',
      'multigenerational',
      'reading',
      'health',
      'languages',
      'nature',
      'schools',
    ],
    description: 'You wouldn\'t believe the skills that your neighbours have. So why not get them together and enjoy the outdoors by creating an open-air classroom. It\'s free and easy to coordinate, simply ask your neighbour what their hidden skill is. Alternatively, take your hobby outdoors and put a sign next to you asking others to join in and learn from you on the spot.',
    image: '155430681.jpg',
  },
  {
    name: 'DIY signage and wayfiding',
    tags: [
      'signage',
      'painting',
      'localtrade',
      'street',
      'park',
      'lingernode',
      'vibrancy',
      'noticeboard',
      'mainstreet',
      'diy',
      'dogs',
      'build',
      'disability',
    ],
    description: 'Whether it\'s signage in a local language, braille, or local slang; or whether it\'s signage to help you find local businesses, amenities and programs, DIY signage is a fun way to inject some colour into your streetscape while also serving a wholesome purpose. Examples: Change rooms over here! Local Market every Sunday! Access ramp this way!',
    image: '868925192.jpg',
  },
  {
    name: 'Walking school bus',
    tags: [
      'active',
      'health',
      'safety',
      'kids',
      'volunteer',
      'walking',
      'exercise',
      'family',
      'childfriendly',
      'pavement',
      'dogs',
      'education',
      'schools',
    ],
    description: 'Toot Toot! Here comes the bus, without a bus! School kids can get a healthy and safe ride to school by being picked up by a Walking School Bus. Get together with some other parent-guardians, map a route to school, and take turns \'leading\' the bus. Start at one house and collect the other \'passengers\' as your tread the sidewalk all the way to the school gate.',
    image: '686890156.jpg',
  },
  {
    name: 'Chalk art festival',
    tags: [
      'pavement',
      'outdoor',
      'multigenerational',
      'arts',
      'chalk',
      'creative',
      'pride',
      'drawing',
      'kids',
      'family',
      'vibrancy',
      'beautification',
      'mud',
      'spring',
    ],
    description: 'Chalk is an affordable and accessible creative medium that is great for creating temporary arts on pavements and driveways. Invite fellow families to join in with a DIY local chalk art festival. Or liaise with authorities to arrange a road closure, tape off formal canvas spaces and fund a group of professional chalk artists to create quality professional pieces that will attract a crowd of visitors.',
    image: '175175106.jpg',
  },
  {
    name: 'Festival of lights',
    tags: [
      'events',
      'festival',
      'safety',
      'mainstreet',
      'lights',
      'outdoor',
      'pride',
      'publicspace',
      'night',
      'family',
      'photography',
      'market',
      'winter',
      'colourful',
    ],
    description: 'Twinkle lights, fairy lights, old lamps, chandeliers, Christmas lights -- bring them all and work together to make a beautiful bright-lit street masterpiece down the main street, or in an area of the park. Use solar lights where possible, or say hello to your local traders and ask if they would be willing to donate power over a weekend. A pop-up festival like this can also be a great chance to demonstrate where new or upgraded lighting could make a difference in your neighbourhood (especially to make public spaces feel safer and warmer, not to mention the beautification that comes with a whole array of lights!)',
    image: '892366948.jpg',
  },
  {
    name: 'Family play groups',
    tags: [
      'family',
      'play',
      'groups',
      'socialconnection',
      'resourcesharing',
      'home',
      'parents',
      'kids',
      'making',
      'education',
      'yards',
      'childfriendly',
      'mud',
      'schools',
    ],
    description: 'Family play groups are a great way for children to get to know and play with other children their age, but are also a great way for parents to meet and socialise. Why not let others in your neighbourhood know when you are going down to the local park. It may even become a monthly gathering. ',
    image: '1070269012.jpg',
  },
  {
    name: 'Local history walking tour',
    tags: [
      'history',
      'walking',
      'volunteer',
      'heritage',
      'storytelling',
      'pride',
      'signage',
      'identity',
      'olderpeople',
      'tours',
      'business',
      'daytime',
      'network',
      'travel',
    ],
    description: 'Local history groups are a rich source of information about local heritage. Liaise with local historians to arrange heritage walking tours to share the quirky and little known facts of our local areas. You will be surprised by how many people show up!',
    image: '948628914.jpg',
  },
  {
    name: 'Adopt a train station',
    tags: [
      'beautification',
      'creative',
      'drawing',
      'chalk',
      'signage',
      'noticeboard',
      'safety',
      'gardening',
      'identity',
      'green',
      'ecofriendly',
      'publictransport',
      'build',
      'bus',
    ],
    description: 'Be it planter bed improvements, knit bombing, painting murals or installing chalk board noticeboards - all the cool cats are forming friends groups, adopting their local train station and holding working bees to create station environments to be proud of.',
    image: '489119482.jpg',
  },
  {
    name: 'Performing arts & street theatre festival ',
    tags: [
      'streetart',
      'arts',
      'storytelling',
      'busking',
      'theatre',
      'music',
      'festival',
      'events',
      'vibrancy',
      'dancing',
      'streetparty',
      'market',
      'instrument',
      'performance',
    ],
    description: 'Turn public spaces into theatre venues. Work with local theatre groups and bring a local performance or play to your neighbourhood park. Or have some fun by hold your own improv session!',
    image: 'Valli-street-artist-with-his-guitar-l.jpg',
  },
  {
    name: 'Community working bee',
    tags: [
      'build',
      'beautification',
      'gardening',
      'home',
      'tools',
      'skillsharing',
      'resourcesharing',
      'gather',
      'diy',
      'yards',
      'childfriendly',
      'mechanic',
      'repairs',
      'summer',
    ],
    description: 'Are you secretly handy with a hammer and paintbrush? Or is your front fence in need of a face-lift? Why not get together with others on your street and host a one day working bee. Its a great way to share the neighbourly love and give your street a bit of a tidy up. ',
    image: '957031184.jpg',
  },
  {
    name: 'Park bench diary / buddy bench',
    tags: [
      'socialconnection',
      'storytelling',
      'books',
      'identity',
      'lingernode',
      'park',
      'publicspace',
      'noticeboard',
      'drawing',
      'olderpeople',
      'writing',
      'reading',
      'belonging',
      'bookclub',
    ],
    description: 'The Bench Diary Project is now a global movement. Join the fun by tying a journal and pen to a park bench and encouraging local people to jot down an entry in the diary. You will be surprised by the window this gives you on your society!',
    image: '912752194.jpg',
  },
  {
    name: 'Arts attendance group',
    tags: [
      'theatre',
      'socialconnection',
      'dancing',
      'olderpeople',
      'photography',
      'tours',
      'reading',
      'publictransport',
      'education',
      'adventure',
      'belonging',
      'disability',
      'instrument',
      'performance',
    ],
    description: 'Ever wanted to go to that concert, or go to that show, but didn\'t have someone to go with? Chances are, you\'re not alone! Buddy groups are popping up to attend theatre performances, gallery exhibits, and garden shows together. Ask your local theatre group if they would consider keeping a \'buddy list\' so that people who ask can go on the list and get in touch with each other before shows.',
    image: '959670488.jpg',
  },
  {
    name: 'Local music pop-up busking festival',
    tags: [
      'festival',
      'events',
      'music',
      'mainstreet',
      'localtrade',
      'busking',
      'pride',
      'youth',
      'identity',
      'vibrancy',
      'popup',
      'market',
      'instrument',
      'performance',
      'spring',
    ],
    description: 'Hold your own music festival by inviting local buskers to perform in your mainstreet or civic space. Don\'t forget to throw a few bucks their way though :)',
    image: '1025929842.jpg',
  },
  {
    name: 'Winter pop-up festival',
    tags: [
      'winter',
      'festival',
      'lights',
      'food',
      'music',
      'events',
      'pride',
      'night',
      'vibrancy',
      'mainstreet',
      'childfriendly',
      'market',
      'autumn',
      'cold',
      'foodtruck',
    ],
    description: 'Ever noticed how parks, squares and streets can feel emptier in winter? There\'s a natural rhythm to hibernating and bunkering indoors, but it can get lonely too. Find a date to gather everyone together, rug up, and embrace the outdoors - Winter Solstice is a great reason! Lights, bonfires, toasting marshmallows, and parades, are just some of the ways you can celebrate the depths of winter and bring some warmth back into the colder months.',
    image: '892366948.jpg',
  },
  {
    name: 'Movie appreciation club ',
    tags: [
      'film',
      'groups',
      'activity',
      'indoor',
      'skillsharing',
      'books',
      'meet',
      'learning',
      'belonging',
      'bookclub',
      'cold',
      'cosy',
      'disability',
      'friends',
      'laughing',
    ],
    description: 'Watched a great movie recently and want to discuss the plot twist? Or are you after a good Netflix recommendation to unwind after a long week at work? A movie appreciation club is a great way to do both. It could be as simple as creating an online forum for sharing suggestions, such as a Facebook group.',
    image: '980494376.jpg',
  },
  {
    name: 'Local arts festival',
    tags: [
      'vibrancy',
      'festival',
      'arts',
      'events',
      'music',
      'gather',
      'meet',
      'bookclub',
      'dancing',
      'drawing',
      'multicultural',
      'theatre',
      'making',
      'autumn',
      'performance',
    ],
    description: 'Why not get a group of local artists and makers together to brighten up your community by coordinate a showcase festival. This could be a one day event or run over an entire week and can involve local traders, businesses and community facilities. ',
    image: '938119414.jpg',
  },
  {
    name: 'Pavement stencilling',
    tags: [
      'play',
      'street',
      'chalk',
      'games',
      'outdoor',
      'walking',
      'arts',
      'beautification',
      'vibrancy',
      'family',
      'mural',
      'pavement',
      'childfriendly',
      'colourful',
      'mud',
    ],
    description: 'Brighten up the footpaths around your neighbourhood, use a pedestrian crossing as your canvas, lay down some temporary arrows and signage, or create a game in 10 seconds!\n\nWe frequently use temporary spray-can chalk paint and some pre-made hopscotch stencils to see what happens on sidewalks near shops, schools, libraries, and parks. Within seconds, any child walking over it starts hopping and jumping! Active, healthy, fun, and full of play, it also brings a spark to the big kids (...adults) too!\n\nA great way to observe natural responses to possible locations for new signage too.',
    image: '465701503.jpg',
  },
  {
    name: 'Community noticeboard',
    tags: [
      'noticeboard',
      'signage',
      'localtrade',
      'publicspace',
      'resourcesharing',
      'skillsharing',
      'accessibility',
      'business',
      'family',
      'multicultural',
      'olderpeople',
      'diy',
      'fashion',
      'belonging',
      'network',
    ],
    description: 'Local neighbourhoods are vibrant places with an abundance of activities happening all day every day. The challenge is finding out where and when they are happening. A notice board is a perfect one-stop way to communication what is happening in your local area. ',
    image: '478438526.jpg',
  },
  {
    name: 'Local arts trail',
    tags: [
      'arts',
      'walking',
      'history',
      'identity',
      'active',
      'vibrancy',
      'storytelling',
      'signage',
      'beautification',
      'photography',
      'tours',
      'making',
      'instagram',
      'spring',
      'travel',
    ],
    description: 'There are hidden art works tucked around street corners and little surprises waiting to be discovered in all neighbourhoods! Contact one of the artists and arrange a tour. If your neighbourhood has very little creativity, then roll up your sleeves and come up with a creative project that you and your neighbours can deliver together. ',
    image: '960753762.jpg',
  },
  {
    name: 'PlayStreets',
    tags: [
      'events',
      'kids',
      'street',
      'safety',
      'play',
      'outdoor',
      'active',
      'family',
      'parents',
      'childfriendly',
      'pavement',
      'socialconnection',
      'daytime',
      'health',
      'mud',
      'schools',
    ],
    description: 'Turn roads into places for people instead of places for cars. Work with your local authorities to close your neighbourhood street and reclaim the space for children to play. Cardboard cities, box races and street cricket recommended!',
    image: '519362633.jpg',
  },
  {
    name: 'Fun run or walkathon',
    tags: [
      'events',
      'fundraising',
      'running',
      'walking',
      'outdoor',
      'park',
      'active',
      'competition',
      'exercise',
      'youth',
      'dogs',
      'bicycle',
      'bushwalk',
      'health',
      'summer',
      'warm',
    ],
    description: 'Need a goal to motivate you to exercise through winter? Or is there a scenic path that runs through your community? Or are you looking to raise money for a local cause? A fun run or walkathon is a great way to get people moving for a good cause. ',
    image: '1023421012.jpg',
  },
  {
    name: 'Local shop and market tours',
    tags: [
      'localtrade',
      'food',
      'tours',
      'market',
      'walking',
      'volunteer',
      'vibrancy',
      'coffee',
      'mainstreet',
      'preserves',
      'business',
      'daytime',
      'instagram',
      'network',
      'shopping',
      'travel',
    ],
    description: 'Local businesses, big or small, have something special to share. Show off these local offerings with a shop local campaign or tour. This could be as simple as footpath trading along your main street one day a month. ',
    image: '638778288.jpg',
  },
  {
    name: 'Neighbourhood facebook group',
    tags: [
      'business',
      'localtrade',
      'noticeboard',
      'resourcesharing',
      'skillsharing',
      'digital',
      'storytelling',
      'network',
      'youth',
      'belonging',
      'conversations',
      'disability',
      'friends',
      'instagram',
      'sparetime',
      'tea',
    ],
    description: 'Create your own \'place brand\' and start up a facebook page or group for your suburb, neighbourhood on mainstreet. It\'s a great way for communities to share information and self organise.',
    image: 'Valli-cafe-break-xxl.jpg',
  },
];
