import React from 'react';
import PropTypes from 'prop-types';
import { Box, Meter, Text } from 'grommet';
import { Like } from 'grommet-icons';


const ProgressBar = ({ likesCount, progressPct }) => (
  <Box flex direction="row" gap="medium">
    <Meter
      values={[{
        value: progressPct,
      }]}
      alignSelf="end"
      thickness="small"
      aria-label="Selection Progress"
      size="full"
      round
    />
    <Box
      flex={{
        shrink: 0,
        grow: 0,
      }}
      direction="row"
      gap="small"
    >
      <Like />
      <Text>{likesCount}</Text>
    </Box>
  </Box>
);

ProgressBar.propTypes = {
  likesCount: PropTypes.number.isRequired,
  progressPct: PropTypes.number.isRequired,
};

export default ProgressBar;
