/**
 * Takes a onClick handler and transforms that
 * to a onKeyPress handler, which triggers the
 * original handler, if the given key was pressed.
 * @param keyTargets: Array of key codes which trigger the handler
 * @param onClick: Click handler function
 * */
// eslint-disable-next-line import/prefer-default-export
export const buildKeyPressHandler = (keyTargets, onClick) => (keyEvent) => {
  // check if pressed key appears in targets
  const keyIsTarget = keyTargets.reduce(
    (prev, targetCode) => prev || targetCode === keyEvent.code,
  );
  // call handler if so
  if (keyIsTarget) {
    onClick(keyEvent);
  }
};
