import React from 'react';
import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';

const ProgressCounter = (
  {
    count, limit, isExceeded, isCompleted,
  },
) => (
  <Box direction="row" justify="end">
    <Text
      weight="bold"
      size="large"
      /* eslint-disable-next-line no-nested-ternary */
      color={isCompleted ? 'accent-1' : isExceeded ? 'status-error' : 'plain'}
    >
      {count}
      /
      {limit}
    </Text>
  </Box>
);

ProgressCounter.propTypes = {
  count: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  isExceeded: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export default ProgressCounter;
