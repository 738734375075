import PropTypes from 'prop-types';

export const tagType = PropTypes.string.isRequired;

export const tagArrayType = PropTypes.arrayOf(
  tagType,
);

export const imgType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  tags: tagArrayType.isRequired,
});

export const imgArrayType = PropTypes.arrayOf(
  imgType,
);

export const areaType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  tags: tagArrayType.isRequired,
});

export const areaArrayType = PropTypes.arrayOf(
  areaType,
);

export const projectType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: tagArrayType.isRequired,
  image: PropTypes.string.isRequired,
});

export const projectArrayType = PropTypes.arrayOf(
  projectType,
);

export const resultType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  email: PropTypes.string,
  location: PropTypes.string,
  areas: areaArrayType.isRequired,
  images: imgArrayType.isRequired,
  projects: projectArrayType.isRequired,
});
