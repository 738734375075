import React from 'react';
import { Button } from 'grommet';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  padding: 5px 15px;
  text-transform: uppercase;
  border-radius: 19px;
  width: 300px;
`;

const SubmitButton = props => <StyledButton {...props} />;


export default SubmitButton;
