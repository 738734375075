// const countTags = array => array.reduce((acc, { tags }) => {
//   // should always have tags, but checking just in case
//   if (tags) {
//     tags.forEach((tag) => {
//       const count = acc[tag] || 0;
//       acc[tag] = count + 1;
//     });
//   }
//   return acc;
// }, {});


import { shuffle } from '../../helpers/array';

/**
 * Takes an array of objects, which hold a list of
 * tags and returns a unique set of all occurring tags.
 * */
const getTagSet = array => (
  array.reduce((set, { tags }) => {
    tags.forEach(tag => set.add(tag));
    return set;
  }, new Set())
);


/**
 * Counts the total occurrences of list
 * entries in a given set.
 * */
const countOccurrences = (array, set) => (
  array.reduce((count, tag) => (
    set.has(tag) ? count + 1 : count
  ), 0)
);


/**
 * Takes a two lists, both containing tagged objects.
 * It returns a copy of the first list, ordered by the
 * (normalized) number of tags occurring across the
 * unified set of tags of the second list.
 * */
const orderByTagOccurrence = (l1, l2) => {
  // get tag set of l2
  const tagSet = getTagSet(l2);
  // todo remove log tagset
  console.log(tagSet);
  // count occurrences per obj in l1 an store in wrapper
  const objectsWithScore = l1.map(obj => ({
    obj,
    score: countOccurrences(obj.tags, tagSet) / obj.tags.length,
  }));
  // sort by score
  objectsWithScore.sort((w1, w2) => w2.score - w1.score);
  // todo remove log objects with score
  // eslint-disable-next-line no-console
  console.log(objectsWithScore.slice(0, 30)
    .map(
      wrapper => [Math.round(wrapper.score * 100), ...wrapper.obj.tags],
    ));
  return objectsWithScore.map(wrapper => wrapper.obj);
};

/**
 * Build the final result objects from:
 * - all possible projects
 * - the users final selection of images
 * - the result of the survey (location, email)
 * */
const buildResult = (projects, images, areas, location = '', email = '') => {
  console.log(projects, images, areas);
  if (!projects || !images || !areas) {
    // eslint-disable-next-line no-console
    console.warn(`
      Cannot build result due to missing data: 
      Projects: ${projects},
      images: ${images},
      areas: ${areas}
    `);
    return null;
  } else {
    const topProjects = orderByTagOccurrence(projects, images)
      .slice(0, 3); // get top 3
    return {
      email,
      location,
      areas,
      images,
      projects: topProjects,
      date: new Date(),
    };
  }
};


/**
 * Returns a list of images, based on the given list of areas.
 * */
const getInitialImages = (images, areas, count) => {
  const orderedImages = orderByTagOccurrence(images, areas)
    .slice(0, count);
  return shuffle(orderedImages);
};

export { buildResult, getInitialImages };
