import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const animationDelay = (num, base) => (
  ((num % 3) + (2 - Math.floor(num / 3))) * base
);

const CubeGrid = styled.div`
  width: 40px;
  height: 40px;
`;

const Cube = styled.div`

  @keyframes cubeAnimation {
    0%, 70%, 100% {
      transform: scale3D(1, 1, 1);
    } 35% {
      transform: scale3D(0, 0, 1);
    } 
  }

  width: 33%;
  height: 33%;
  background-color: ${({ theme, color }) => theme.global.colors[color]};
  float: left;
  animation: cubeAnimation 1.3s infinite ease-in-out; 
  animation-delay: ${({ num }) => animationDelay(num, 0.1)}s;
`;


const Spinner = ({ color }) => (
  <CubeGrid className="sk-cube-grid">
    {
      [...Array(9)
        .keys()]
        .map(num => (
          <Cube num={num} key={num} color={color} />
        ))
    }
  </CubeGrid>
);

Spinner.defaultProps = {
  color: 'black',
};

Spinner.propTypes = {
  color: PropTypes.string,
};


export default Spinner;
