import styled from 'styled-components';


/**
 * Renders all subsequent images in a square frame.
 * Only the first image is actually displayed.
 * */
const ImagesContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 100%;
  background-color: #eeeeee;
  
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    &:first-child {
      object-fit: cover;
      height: 100%;
    }
    &:not(:first-child) {
      visibility: hidden;
      z-index: -1;
    }
  }
`;

export default ImagesContainer;
