import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { imgType } from '../../helpers/propTypes';
import Image from '../Image';

const imgSize = 30;
const imgSizeL = 50;
const gap = 10;


const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  // grey line
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 30px;
    right: 30px;
    z-index: -1;
    height: 1px;
    background-color: ${({ theme }) => theme.global.colors['light-4']};
  }
`;

const BgWrapper = styled.div`
  min-width: 10px;
  background-color: white;
  display: flex;
  padding: 0 30px;
  margin: -${gap / 2}px 0;
`;

const ImageWrapper = styled.div`
  width: ${imgSize}px;
  height: ${imgSize}px;
  border-radius: 100%;
  overflow: hidden;
  margin: ${gap / 2}px;
  background-color: ${({ theme }) => theme.global.colors['light-1']};
  
  @media(min-width: ${({ theme }) => theme.global.breakpoints.small.value}px) {
    width: ${imgSizeL}px;
    height: ${imgSizeL}px;
  }
`;

const SyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Images = ({ images }) => (
  <ImagesContainer>
    <BgWrapper>
      {
        images.map(img => (
          <ImageWrapper key={img.name}>
            <SyledImage name={img.name} />
          </ImageWrapper>
        ))
      }
    </BgWrapper>
  </ImagesContainer>
);

Images.propTypes = {
  images: PropTypes.arrayOf(imgType).isRequired,
};


export default Images;
