import styled from 'styled-components';


/**
 * Wrapper that layouts header and content in a flex column.
 * The wrapper takes at least the full window height.
 * */
const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
  
  ::after {
    content: '';
    display: block;
    width: 100vw;
    height: 150vh;
    background: #FDB813;
    position: fixed;
    transform: rotate(15deg);
    top: -20vh;
    right: -60vw;
  }
`;

export default Wrapper;
