const buildCsv = (results) => {
  /**
   * @param Array results: A collection of result objects
   * @return String csvContent: csv content, in string
   * Build CSV content as a single string.
   * */
  const headerCells = [
    'id', 'email', 'date', 'location', 'areas',
    'project1', 'project2', 'project3',
  ];

  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += `${headerCells.join(',')}\r\n`;
  results.forEach((result) => {
    const rowArray = headerCells.map((header) => {
      switch (header) {
        case 'project1':
          return result.projects[0].hasOwnProperty('name') && result.projects[0]['name'];
        case 'project2':
          return result.projects[1].hasOwnProperty('name') && result.projects[1]['name'];
        case 'project3':
          return result.projects[2].hasOwnProperty('name') && result.projects[2]['name'];
        case 'areas':
          return result.areas.map(x => x.name).join('|');
        case 'date':
          return new Date(result.date.seconds * 1000).toLocaleDateString();
        default:
          return result.hasOwnProperty(header) && result[header];
      }
    });
    csvContent += `${rowArray.join(',')}\r\n`;
  });
  return csvContent;
};

const downloadCsv = (results, fromDate, toDate) => {
  const csvContent = buildCsv(results);
  // Allow CSV to be downloaded as file via browser
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  const filename = `results${new Date(fromDate).toLocaleDateString()}-to-${new Date(toDate).toLocaleDateString()}.csv`.replace(/\//g, '-');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for FF

  link.click();
};

export default downloadCsv;
