/**
 * Takes an arbitrary array and returns a shuffled copy, 
 * using the Fisher-Yates algorithm.
 * https://bost.ocks.org/mike/shuffle/
 */
export const shuffle = (array) => {
    const randomArray = array.slice()  // create a copy
    var m = randomArray.length, t, i;
    while (m) {
        i = Math.floor(Math.random() * m--);
        t = randomArray[m];
        randomArray[m] = randomArray[i];
        randomArray[i] = t;
    }
    return randomArray;
}