import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Text } from 'grommet';
import { buildKeyPressHandler } from '../../helpers/event';


const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  
  box-sizing: border-box;
  border: 3px solid transparent;
  
  transition: background-color 0.1s ease-out;
  background-color: ${({ theme }) => theme.global.colors['light-1']};
  ${({ checked }) => checked && css`
    background-color: ${({ theme }) => theme.global.colors['accent-1']};
    color: white;
  `}
  
  &:hover, &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.global.colors['light-3']};
    
    ${({ checked }) => checked && css`
      border-color: ${({ theme }) => theme.global.colors['accent-1-dark']};
    `}
  }
`;


const InvisibleInput = styled.input`
  position: absolute;
  visibility: hidden;
  z-index: -1;
  left: 0;
  right: 0;
  pointer-events: none;
`;


const CheckButton = (props) => {
  const {
    label, name, checked, value, onChange,
  } = props;

  const id = `${name}_${value}`;

  const keyEvent = buildKeyPressHandler(
    [13, 32],
    (e) => {
      // prevent default for key event
      e.preventDefault();
      // emulate input event
      onChange({
        target: {
          name,
          value,
          checked: !checked,
        },
      });
    },
  );

  return (
    <StyledLabel
      checked={checked}
      htmlFor={id}
      tabIndex={0}
      onKeyPress={keyEvent}
    >
      <Text>{label}</Text>
      <InvisibleInput
        id={id}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
    </StyledLabel>
  );
};

CheckButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckButton;
