export default [
  {
    name: 'Greenery',
    tags: [
      'environment',
      'green',
      'nature',
      'trees',
    ],
  },
  {
    name: 'Beauty',
    tags: [
      'beautification',
      'arts',
      'mural',
      'chalk',
    ],
  },
  {
    name: 'Attractive public transport stops',
    tags: [
      'gardening',
      'disability',
      'publictransport',
      'beautification',
    ],
  },
  {
    name: 'Cultural connection',
    tags: [
      'multicultural',
      'identity',
      'culturalconnection',
      'indigenous',
    ],
  },
  {
    name: 'Large-scale mural art ',
    tags: [
      'mural',
      'beautification',
      'streetart',
      'chalk',
    ],
  },
  {
    name: 'Support during major place change',
    tags: [
      'localtrade',
      'signage',
      'noticeboard',
      'network',
      'identity',
    ],
  },
  {
    name: 'Support for local traders',
    tags: [
      'localtrade',
      'market',
      'business',
      'mainstreet',
    ],
  },
  {
    name: 'Dog-friendly spaces',
    tags: [
      'publicspace',
      'park',
      'dogs',
      'meet',
    ],
  },
  {
    name: 'Spaces for sport and recreation',
    tags: [
      'sports',
      'wellbeing',
      'exercise',
      'active',
    ],
  },
  {
    name: 'Family-friendly activities',
    tags: [
      'kids',
      'family',
      'childfriendly',
      'activity',
      'disability',
    ],
  },
  {
    name: 'Great accessibility',
    tags: [
      'accessibility',
      'disability',
      'signage',
      'publictransport',
    ],
  },
  {
    name: 'Authentic identity',
    tags: [
      'history',
      'identity',
      'storytelling',
      'indigenous',
      'culturalconnection',
      'heritage',
    ],
  },
  {
    name: 'Spaces to gather and meet',
    tags: [
      'meet',
      'socialconnection',
      'gather',
      'network',
      'publicspace',
      'lingernode',
    ],
  },
  {
    name: 'Heritage preservation',
    tags: [
      'heritage',
      'history',
      'indigenous',
      'multicultural',
      'storytelling',
    ],
  },
  {
    name: 'More open outdoor spaces',
    tags: [
      'nature',
      'outdoor',
      'park',
      'publicspace',
      'green',
    ],
  },
];
