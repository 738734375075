export const SURVEY = 'survey';
export const SWIPER = 'swiper';
export const PICKER = 'picker';
export const RESULT = 'result';
export const RESULT_LIST = 'result_list';
export const CSV_RESULTS = 'csv_results';

export const SAVING_RESULT = 'saving_result';
export const LOADING_RESULT = 'loading_result';

export const ERR_NOT_FOUND = 'err_not_found';
export const ERR_INTERNAL = 'err_internal';
